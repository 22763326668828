import axios from "axios";
import { IQuestion } from "../store/questions.reducer";

const fetch = async (vacancy_id: string, params?: any) => {
  try {
    const response = await axios.get<IQuestion[]>(`/vacancies/${vacancy_id}/questions`, {
      params,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const add = async (vacancy_id: string, params: any) => {
  try {
    const response = await axios.post<IQuestion>(`/vacancies/${vacancy_id}/questions`, params);
    return response.data;
  } catch (error) {
    throw new Error("Ошибка при добавлении нового вопроса. Попробуйте позднее.");
  }
};

const update = async (vacancy_id: string, question_id: string, data: any) => {
  try {
    const response = await axios.patch<IQuestion>(
      `/vacancies/${vacancy_id}/questions/${question_id}`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error("Ошибка при обновлении вопроса. Попробуйте позднее.");
  }
};

const sendAnswers = async (vacancy_id: string, answers: Partial<IQuestion>[]): Promise<any> => {
  //const response = await axios.post<IQuestion[]>(`/vacancies/${vacancy_id}/questions`);
  return new Promise((resolve) => setTimeout(() => resolve({ result: true }), 1000));
};

const QuestionsAPI = { add, fetch, update, sendAnswers };

export default QuestionsAPI;
