import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";

import { store } from "./store";

// Указываем дефолтный URL для запросов
axios.defaults.baseURL = process.env.REACT_APP_BACKEND;

// Перехватываем запросы и добавляем туда токен из хранилища
axios.interceptors.request.use(function (config) {
  const token = store.getState().auth.token;
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
