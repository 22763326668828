import Button from "antd/lib/button";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Input from "antd/lib/input";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import ResumesAPI from "../../api/resumes.api";
import { IResume, updateResume } from "../../store/resumes.reducer";

const PopupWrapper = styled.div`
  max-width: 400px;
  padding: 1.5rem;
`;

const PopupText = styled.div`
  margin-bottom: 1rem;
`;

type EmailNotDetectedProps = {
  resume: Partial<IResume>;
};

const EmailNotDetected: React.FC<EmailNotDetectedProps> = ({ resume }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSaveEmail = async (values: any) => {
    const { email } = values;
    setIsLoading(true);
    if (!resume.vacancy_id || !resume.id) return;
    try {
      const updatedResume = await ResumesAPI.update(resume.vacancy_id, resume.id, { email });
      if (updatedResume) {
        dispatch(updateResume(updatedResume));
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupWrapper>
      <PopupText>
        В резюме <b>{resume.name}</b> не найден E-mail! Вы можете указать его ниже, или отправить
        ссылку на интервью в ручную.
      </PopupText>
      <Form onFinish={handleSaveEmail}>
        <Row gutter={8}>
          <Col span={16}>
            <Form.Item name="email" label="E-mail" required>
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Сохранить
            </Button>
          </Col>
        </Row>
      </Form>
    </PopupWrapper>
  );
};

export default EmailNotDetected;
