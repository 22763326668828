import { Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from "../../../store";
import { IVacancy } from "../../../store/vacancies.reducer";
import { Smile, InterviewContent, InterviewWrapper } from "./interview.styled";
import { setQuestions } from "../../../store/questions.reducer";
import { InterviewAPI } from "../../../api/interview.api";
import { declension } from "../../../helpers/declension";

type RouteParams = {
  interview_link_id: string;
};

const Interview: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { interview_link_id } = match.params;
  const dispatch = useDispatch();
  const { questions } = useSelector((state: RootState) => state);
  const [vacancy, setVacancy] = useState<IVacancy | undefined>();

  const questionsCount = declension(questions.length, ["вопрос", "вопроса", "вопросов"]);

  /**
   * Подгружаем информацию о вакансии
   */
  useEffect(() => {
    (async () => {
      try {
        const interview = await InterviewAPI.getVacancy(interview_link_id);
        setVacancy(interview.vacancy);
      } catch (error) {}
    })();
  }, [interview_link_id]);

  /**
   * Подгружаем список вопросов для текущего резюме
   */
  useEffect(() => {
    (async () => {
      try {
        const questions = await InterviewAPI.getQuestions(interview_link_id);
        if (questions) {
          dispatch(setQuestions(questions));
        }
      } catch (error) {}
    })();
  }, [dispatch, interview_link_id]);

  if (vacancy?.interview_status !== "running") {
    return (
      <InterviewWrapper>
        <InterviewContent>
          <Smile>👋</Smile>
          <p>Это интервью ещё не запущено!</p>
        </InterviewContent>
      </InterviewWrapper>
    );
  }

  return (
    <InterviewWrapper>
      <InterviewContent>
        <Smile>👋</Smile>
        <p>
          Приветствуем вас на аудиоинтервью на вакансию <b>{vacancy?.name}</b>.
        </p>
        <p>
          У нас всего <b>{questions.length}</b> {questionsCount}, поэтому интервью займет немного
          времени. На вопросы надо будет ответить голосом.
        </p>
        <Link to={`/interview/${interview_link_id}/0`}>
          <Button type="primary" size="large" block>
            Начать
          </Button>
        </Link>
      </InterviewContent>
    </InterviewWrapper>
  );
};

export default Interview;
