import styled from "styled-components";
import lamp from "../assets/lamp.svg";

export const LegendMessage = styled.div`
  background: #ffffff;
  color: #5b6673;
  background-image: url(${lamp});
  background-repeat: no-repeat;
  background-position: 15px center;
  border: 1px solid #c1cce7;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px 15px 10px 50px;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
  }
`;

export const InfoMessage = styled.div<{ align?: "left" | "right" | "center" }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: ${({ align = "left" }) => align};
  color: #3a4a61;
  background-image: url(${lamp});
  background-repeat: no-repeat;
  background-position: center 15px;
  padding: 45px 15px 15px;
`;

export const ColoredSpan = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

export const HighLight = styled.b<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: inherit;
  text-transform: initial;
`;
