import Button from "antd/lib/button";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Row from "antd/lib/row";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { Folder, PaperDownload, Search } from "react-iconly";

import ContentWrapper from "../components/ContentWrapper";
import { WhiteAroundBlock } from "../shared/block.styled";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { fetchPositions } from "../store/positions.reducer";
import { IFile } from "../store/files.types";
import { useVacanciesStatus } from "../hooks/vacancy.hook";
import Processing from "../components/Processing";
import VacancyAPI from "../api/vacancies.api";
import { addVacancy } from "../store/vacancies.reducer";
import { StyledSelect, Uploader } from "../shared/forms.styled";

const FooterControl = styled.div`
  text-align: right;
`;

const Vacancy = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state: RootState) => state.auth.token);
  const positions = useSelector((state: RootState) => state.positions);
  const [fileId, setFileId] = useState<string>();
  const [positionId, setPositionId] = useState<string | undefined>();

  const { isAvailable } = useVacanciesStatus();

  useEffect(() => {
    positions.length === 0 && dispatch(fetchPositions());
  }, [dispatch, positions.length]);

  /**
   * Сохраняем id загруженного файла в стейт
   */
  const handleChangeFiles = (info: UploadChangeParam<UploadFile<IFile>>) => {
    const { status } = info.file;
    if (status === "done") {
      setFileId(info.file.response?.id);
      message.success(`${info.file.name} успешно загружен.`);
    } else if (status === "error") {
      message.error(`${info.file.name} не удалось загрузить.`);
    }
  };

  const actionProps = {
    action: `${process.env.REACT_APP_BACKEND}/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  /**
   * Обрабатываем клик на кнопку "Далее"
   */
  const handleMoveNext = async () => {
    if (!positionId) return;
    try {
      // Сохраняем вакансию на сервер
      const vacancy = await VacancyAPI.add({
        position_id: positionId,
        file_id: fileId,
      });
      // Добавляем новую вакансию
      dispatch(addVacancy(vacancy));
      // Если файл есть
      if (fileId) {
        // Перенаправляем к списке резюме
        history.push(`/vacancies`);
      } else {
        // Иначе сразу к маппингу
        history.push(`/vacancy/${vacancy.id}`);
      }
    } catch (error) {}
  };

  if (!isAvailable) {
    return <Processing />;
  }

  const options = positions.map((item) => ({ label: item.name, value: item.id }));

  return (
    <ContentWrapper
      title="Разметка вакансии"
      icon={<Folder set="light" primaryColor="#98A4BC" size={20} />}
      onBack={() => null}
      footerContent={
        <FooterControl>
          <Button size="large" type="primary" disabled={!positionId} onClick={handleMoveNext}>
            Далее
          </Button>
        </FooterControl>
      }
    >
      <WhiteAroundBlock>
        <Row>
          <Col span={12} offset={6}>
            <StyledSelect
              suffixIcon={<Search set="light" primaryColor="#5061DC" size={20} />}
              allowClear
              showSearch
              size="large"
              options={options}
              optionFilterProp="label"
              onChange={(value: unknown) => setPositionId(value as string)}
              placeholder="Введите название профессиональной области..."
            />
          </Col>
          <Col span={24} style={{ paddingBottom: 50 }}>
            <Uploader
              multiple={false}
              maxCount={1}
              showUploadList={false}
              accept=".txt,.doc,.docx,.pdf"
              data={(file) => ({ name: file.name })}
              onChange={handleChangeFiles}
              {...actionProps}
            >
              <p className="ant-upload-text">
                <PaperDownload set="light" primaryColor="#5061DC" size="medium" />
                <span>Загрузить описание вакансии</span>
              </p>
              <p className="ant-upload-hint">Форматы: .PDF, .DOCX, .DOC, .TXT</p>
            </Uploader>
          </Col>
          <Col span={24}>
            <p className="ant-upload-count">Загружено файлов: {fileId ? 1 : 0}</p>
          </Col>
        </Row>
      </WhiteAroundBlock>
    </ContentWrapper>
  );
};

export default Vacancy;
