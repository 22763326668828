import styled, { keyframes } from "styled-components";

export const InterviewButtonsWrapper = styled.div`
  width: 260px;
  margin-left: 2rem;
`;

export const InterviewButton = styled.button<{ primary?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid #c1cce7;
  border-radius: 12px;
  background-color: ${({ primary }) => (primary ? "#5061DC" : "transparent")};
  color: ${({ primary }) => (primary ? "#fff" : "#5061DC")};
  padding: 20px 10px;
  margin-bottom: 1rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  &:disabled {
    background-color: transparent;
    color: #98a4bc;
    cursor: not-allowed;
  }
  span {
    margin-bottom: 0.7rem;
    font-size: 1.75rem;
  }
`;

const blink = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0.1
  }
`;
export const InterviewRecording = styled.span`
  color: #ff0000;
  font-weight: 500;
  font-size: 11px !important;
  text-align: center;

  &::before {
    display: inline-block;
    width: 10px;
    height: 10px;
    content: "";
    background-color: #ff0000;
    border-radius: 50%;
    margin-right: 5px;
    animation: ${blink} 1s linear infinite;
    animation-direction: alternate-reverse;
  }
`;

export const InterviewDuration = styled.div`
  text-align: center;
  color: #c1cce7;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 1rem;
`;
