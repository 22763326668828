import axios from "axios";

const fetch = async () => {
  try {
    const response = await axios(`/audit`);
    return response.data;
  } catch (error) {
    throw new Error("Ошибка при загрузке задач");
  }
};

const save = async (id: string, approved_entities?: string[]) => {
  try {
    const response = await axios.patch(`/audit/${id}`, { approved_entities });
    return response.data;
  } catch (error) {
    throw new Error("Ошибка при сохранении");
  }
};

export const AuditAPI = { fetch, save };
