import { ShieldDone } from "react-iconly";
import styled from "styled-components";

const CommunicationVerifyWrapper = styled.div<{ is_according: boolean }>`
  background: ${({ is_according }) => (is_according ? "#fff8df" : "#E8ECEF")};
  border: 1px solid ${({ is_according }) => (is_according ? "#f5bf00" : "#979797")};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: ${({ is_according }) => (is_according ? "#f5bf00" : "#979797")};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  p {
    margin: 1rem 0 0.5rem;
  }
`;

type CommunicationVerifyProps = {
  is_according: boolean;
};

function CommunicationVerify({ is_according }: CommunicationVerifyProps) {
  return (
    <CommunicationVerifyWrapper is_according={is_according}>
      <ShieldDone set="two-tone" primaryColor={is_according ? "#F5BF00" : "#979797"} size="large" />
      <p>
        {is_according ? "Соответсвуют требуемому уровню" : "Не соответствуют требуемому уровню"}
      </p>
    </CommunicationVerifyWrapper>
  );
}

export default CommunicationVerify;
