import { FC, Children, PropsWithChildren, ReactElement, ReactNode } from "react";
import styled from "styled-components";

const TabsWrapper = styled.div``;

const TabNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const TabMenu = styled.div`
  display: flex;
`;

const TabExtra = styled.div``;

const TabButton = styled.button<{ isActive: boolean; color?: string }>`
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 6px 12px;
  border-bottom: 2px solid ${({ color, isActive }) => (isActive ? color : "transparent")};
  color: ${({ color, isActive }) => (isActive ? color : "#ccc")};
`;

const TabsContainer = styled.div``;

const TabPane: FC<TabPaneProps> = ({ title }) => <>{title}</>;

interface TabsProps {
  children: ReactElement[];
  activeTab: string;
  onChange: (key: string) => void;
  extra?: ReactNode;
}

interface TabPaneProps {
  title: string;
  key: string;
  children: React.ReactElement;
  color?: string;
}

export interface ITabs extends React.FC<TabsProps> {
  TabPane: typeof TabPane;
}

const Tabs: ITabs = ({ children, activeTab, onChange, extra }) => {
  return (
    <TabsWrapper>
      <TabNavigation>
        <TabMenu>
          {Children.map(children, (child: ReactElement<PropsWithChildren<TabPaneProps>>) => (
            <TabButton
              isActive={child.key === activeTab}
              color={child.props.color}
              onClick={() => child.key && onChange(child.key.toString())}
            >
              {child}
            </TabButton>
          ))}
        </TabMenu>
        {extra && <TabExtra>{extra}</TabExtra>}
      </TabNavigation>
      <TabsContainer>
        {Children.map(
          children,
          (item: ReactElement<PropsWithChildren<TabPaneProps>>) =>
            item.key === activeTab && item.props.children
        )}
      </TabsContainer>
    </TabsWrapper>
  );
};

Tabs.TabPane = TabPane;

export default Tabs;
