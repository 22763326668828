import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";

const DynamicIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: inline-block;
    width: 3px;
    height: 35px;
    margin: 0 5px;
    background: #5061dc;
    transform: scaleY(0.5);
    opacity: 0.25;
  }
`;

type InterviewDynamicIconProps = {
  analyser: AnalyserNode;
};

const InterviewDynamicIcon: React.FC<InterviewDynamicIconProps> = ({ analyser }) => {
  const canvasRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!canvasRef.current) return;
    if (!analyser) return;

    const dataMap: { [key: number]: number } = {
      0: 15,
      1: 10,
      2: 8,
      3: 9,
      4: 6,
      5: 5,
      6: 2,
      7: 1,
      8: 0,
      9: 4,
      10: 3,
      11: 7,
      12: 11,
      13: 12,
      14: 13,
      15: 14,
    };
    let raf: number;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    for (let i = 0; i < bufferLength; ++i) {
      const elm = document.createElement("div");
      canvasRef.current.appendChild(elm);
    }

    let visualElements = canvasRef.current.querySelectorAll("div");

    const draw = () => {
      if (!canvasRef.current) return;
      const values = Object.values(dataArray);

      raf = requestAnimationFrame(draw);
      analyser.getByteFrequencyData(dataArray);

      for (let i = 0; i < bufferLength; i++) {
        const value = values[dataMap[i]] / 255;
        const elmStyles = visualElements[i].style;
        elmStyles.transform = `scaleY( ${value} )`;
        elmStyles.opacity = `${Math.max(0.25, value)}`;
      }
    };
    draw();
    return () => {
      cancelAnimationFrame(raf);
    };
  }, [analyser]);

  return <DynamicIcon ref={canvasRef}></DynamicIcon>;
};

export default InterviewDynamicIcon;
