import { createSlice } from "@reduxjs/toolkit";

export interface IUser {
  id: string;
  username: string;
  email: string;
  avatar: string;
  role: "admin" | "guest";
}

const initialState: IUser[] = [];

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers(state, action) {
      return action.payload;
    },
  },
});

const { actions, reducer } = usersSlice;

export const { setUsers } = actions;

export default reducer;
