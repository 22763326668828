import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PositionsAPI from "../api/positions.api";

export interface IPosition {
  id: string;
  name: string;
}

const initialState: IPosition[] = [];

export const fetchPositions = createAsyncThunk("positions/fetch", async () => {
  const positions = await PositionsAPI.fetch();
  return positions;
});

const positionsSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {
    setPositions(_, { payload }) {
      return payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPositions.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer } = positionsSlice;

export const { setPositions } = actions;

export default reducer;
