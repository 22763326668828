import axios from "axios";
import { IAuth } from "../store/auth.reducer";

export const whoami = async (): Promise<IAuth | undefined> => {
  try {
    const req = await axios("/users/whoami");
    return req.data;
  } catch (error) {
    localStorage.removeItem("token");
  }
};

export const signin = async (email: string, password: string): Promise<IAuth> => {
  try {
    const req = await axios.post("/login", { email, password });
    return req.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error("Неверный логин или пароль");
    } else {
      throw new Error("Произошла неизвестная ошибка");
    }
  }
};
