import { useEffect, useMemo } from "react";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import FilesAPI from "../../api/files.api";
import { useVoiceRecorder } from "../../hooks/voice.hook";
import {
  InterviewContent,
  InterviewQuestionText,
} from "../../pages/guest/interview/interview.styled";
import { IQuestion, setAnswer } from "../../store/questions.reducer";
import InterviewStartButton from "./InterviewStartButton";
import {
  InterviewButton,
  InterviewButtonsWrapper,
  InterviewDuration,
} from "./interview_buttons.styled";

type InterviewQuestionProps = {
  question: IQuestion;
};

const InterviewQuestion: React.FC<InterviewQuestionProps> = ({ question }) => {
  const dispatch = useDispatch();

  const timeLimit = 120;
  const {
    duration,
    analyser,
    statusRecording,
    startRecording,
    stopRecording,
    clearRecording,
    isPlaying,
    startPlaying,
    stopPlaying,
  } = useVoiceRecorder(
    (data) => {
      saveAnswer(data);
    },
    {
      timeLimit,
      enableByteMonitor: true,
    }
  );

  /**
   * Добавляем новый ответ
   */
  const saveAnswer = async (blob: Blob) => {
    try {
      // загружаем файл на сервер
      const file = await FilesAPI.upload(`Recording for ${question.id}`, blob);
      if (file) {
        // Записываем ответ
        dispatch(setAnswer({ id: question.id, answer: file.id }));
      }
    } catch (error) {}
  };

  /**
   * Запускаем запись звука с микрофона
   */
  const handleStartRecording = () => {
    startRecording();
    dispatch(setAnswer({ id: question.id, answer: undefined }));
  };

  // Оставшееся время записи
  const timeRecording = useMemo(() => {
    const time = new Date();
    time.setHours(0, 0, timeLimit, 0);
    time.setSeconds(time.getSeconds() - duration);
    return `${("0" + time.getMinutes()).slice(-2)}:${("0" + time.getSeconds()).slice(-2)}`;
  }, [duration]);

  /**
   * Если изменился айди вопроса, чистим запись и статус
   */
  useEffect(() => {
    if (question.id)
      return () => {
        clearRecording();
      };
  }, [question.id, clearRecording]);

  const url = process.env.REACT_APP_BACKEND + "/download/" + question.answer;

  return (
    <InterviewContent width="100%" horizontal>
      <InterviewQuestionText>{question.text}</InterviewQuestionText>
      <InterviewButtonsWrapper>
        <InterviewStartButton
          onStart={handleStartRecording}
          status={statusRecording}
          onStop={stopRecording}
          analyser={analyser}
        />
        <InterviewDuration>Осталось {timeRecording}</InterviewDuration>
        {isPlaying ? (
          <InterviewButton onClick={() => stopPlaying()}>
            <PauseCircleOutlined /> Остановить
          </InterviewButton>
        ) : (
          <InterviewButton onClick={() => startPlaying(url)} disabled={!question.answer}>
            <PlayCircleOutlined /> Прослушать
          </InterviewButton>
        )}
      </InterviewButtonsWrapper>
    </InterviewContent>
  );
};

export default InterviewQuestion;
