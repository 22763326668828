import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import { Scan } from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { RootState } from "../../store";
import { typeColor } from "../../helpers/typeColor";
import ContentWrapper from "../../components/ContentWrapper";
import EntityTag from "../../components/EntityTag";
import { ScrolledBlock, WhiteAroundBlock } from "../../shared/block.styled";
import { FooterControl } from "../../shared/footer.styled";
import { AuditAPI } from "../../api/audit.api";
import { message } from "antd";
import { fetchTasks, removeTask, toggleDisabled } from "../../store/tasks.reducer";
import { useEffect } from "react";

const { confirm } = Modal;

type RouteParams = {
  id: string;
};

const ModerationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

const ModerationTask: React.FC<RouteComponentProps<RouteParams>> = ({ match, history }) => {
  const { id: task_id } = match.params;
  const dispatch = useDispatch();
  const tasks = useSelector((state: RootState) => state.tasks);
  const task = tasks.find((task) => task.id === task_id);

  useEffect(() => {
    tasks.length === 0 && dispatch(fetchTasks());
  }, [dispatch, tasks.length]);

  // Удаляем выбранный элемент из списка
  const toggleEntity = (entity_id: string) => {
    dispatch(toggleDisabled({ task_id, entity_id }));
  };

  /**
   * Обрабатываем клик по кнопке "Назад"
   */
  const handleBack = () =>
    confirm({
      title: "Указанное действие приведет к потере несохраненных данных. Всё равно продолжить?",
      onOk() {
        history.push("/moderation");
      },
    });

  const handleSave = async () => {
    const entities = task?.entities
      .filter((item) => !item.is_disabled)
      .map((item) => item.entity_id);
    try {
      const result = await AuditAPI.save(task_id, entities);
      if (result) {
        dispatch(removeTask(task_id));
        history.goBack();
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <ContentWrapper
      title="Разметка текста"
      icon={<Scan set="light" primaryColor="#98A4BC" size={20} />}
      onBack={() => history.goBack()}
      footerContent={
        <FooterControl>
          <Button size="large" type="default" onClick={handleBack}>
            Назад
          </Button>
          <Button size="large" type="primary" onClick={handleSave}>
            Сохранить
          </Button>
        </FooterControl>
      }
    >
      <ModerationWrapper>
        <WhiteAroundBlock>
          <ScrolledBlock height={500}>{task?.text}</ScrolledBlock>
        </WhiteAroundBlock>
        <WhiteAroundBlock>
          <ScrolledBlock height={500}>
            {task?.entities.map((entity) => (
              <EntityTag
                key={entity.entity_id}
                color={typeColor[entity.type]}
                {...entity}
                onRemove={toggleEntity}
              />
            ))}
          </ScrolledBlock>
        </WhiteAroundBlock>
      </ModerationWrapper>
    </ContentWrapper>
  );
};

export default ModerationTask;
