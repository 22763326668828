import { PlusCircleOutlined } from "@ant-design/icons";
import Button from "antd/lib/button";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import TextArea from "antd/lib/input/TextArea";
import { message } from "antd";
import Modal from "antd/lib/modal";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Voice } from "react-iconly";

import ContentWrapper from "../components/ContentWrapper";
import EntityTag from "../components/EntityTag";
import TableWrapper from "../components/TableWrapper";
import { typeColor } from "../helpers/typeColor";
import { typeQuestion } from "../helpers/typeQuestion";
import { FooterContent, FooterControl } from "../shared/footer.styled";
import { ColoredSpan, HighLight, InfoMessage, LegendMessage } from "../shared/text.styled";
import { RootState } from "../store";
import { IEntity } from "../store/entities.reducer";
import {
  addQuestion,
  fetchQuestions,
  IQuestion,
  QuestionType,
  updateQuestion,
} from "../store/questions.reducer";
import { fetchVacancies, updateVacancy } from "../store/vacancies.reducer";
import AudioInterviewSettingsModal from "./AudioInterviewSettingsModal";
import { InterviewSettingsAPI } from "../api/interview.api";
import { WhiteAroundBlock } from "../shared/block.styled";
import { fetchResumes } from "../store/resumes.reducer";
import { StyledSelect } from "../shared/forms.styled";
import QuestionsAPI from "../api/questions.api";
import styled from "styled-components";

type RouteParams = {
  vacancy_id: string;
};

const TextButton = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  color: #3a4a61;
  white-space: normal;
  text-align: left;
  padding: initial;
  height: auto;
  cursor: text;
  &:hover {
    background: transparent;
  }
`;

const AudioInterviewQuestions: React.FC<RouteComponentProps<RouteParams>> = ({
  match,
  history,
}) => {
  const [form] = Form.useForm();
  const { vacancy_id } = match.params;
  const dispatch = useDispatch();
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [resumeId, setResumeId] = useState<string | undefined>();
  const [changeableName, setChangeableName] = useState<string | undefined>();
  const { vacancies, questions, resumes } = useSelector((state: RootState) => state);
  const vacancy = vacancies.find((vacancy) => vacancy.id === vacancy_id);

  useEffect(() => {
    vacancies.length === 0 && dispatch(fetchVacancies());
  }, [dispatch, vacancies.length]);

  /**
   * Подгружаем список вопросов для текущей вакансии
   */
  useEffect(() => {
    resumeId && dispatch(fetchQuestions({ vacancy_id, params: { resume_id: resumeId } }));
  }, [dispatch, resumeId, vacancy_id]);

  /**
   * Подгружаем список резюме
   */
  useEffect(() => {
    resumes.length === 0 && dispatch(fetchResumes(vacancy_id));
  }, [dispatch, vacancy_id, resumes.length]);

  useEffect(() => {
    resumes.length > 0 && setResumeId(resumes[0].id);
  }, [resumes]);

  /**
   * Включаем/Отключаем вопрос из аудио интервью
   * @param e Событие чекбокса
   * @param question Вопрос
   */
  const handleEnable = async (e: CheckboxChangeEvent, question: IQuestion) => {
    const is_enabled = e.target.checked;
    try {
      const updatedQuestion = await QuestionsAPI.update(vacancy_id, question.id, { is_enabled });
      if (updatedQuestion) {
        dispatch(updateQuestion(updatedQuestion));
      }
    } catch (error) {}
  };

  const handleBlurInputName = async (text: string, question: IQuestion) => {
    try {
      const updatedQuestion = await QuestionsAPI.update(vacancy_id, question.id, { text });
      if (updatedQuestion) {
        dispatch(updateQuestion(updatedQuestion));
      }
    } catch (error) {
    } finally {
      setChangeableName(undefined);
    }
  };

  const columns: ColumnsType<IQuestion> = [
    {
      title: "",
      dataIndex: "is_enabled",
      render: (_, question) => (
        <Checkbox checked={question.is_enabled} onChange={(e) => handleEnable(e, question)} />
      ),
    },
    {
      title: "Вопрос",
      dataIndex: "text",
      render: (text, question) => {
        if (question.type === "caliber") {
          if (changeableName === question.id) {
            return (
              <Input
                defaultValue={question.text}
                bordered={false}
                onBlur={(e: any) => handleBlurInputName(e.target.value, question)}
                onPressEnter={(e: any) => handleBlurInputName(e.currentTarget.value, question)}
              />
            );
          } else {
            return (
              <TextButton type="text" onClick={() => setChangeableName(question.id)}>
                {text}
              </TextButton>
            );
          }
        } else {
          return text;
        }
      },
    },
    {
      title: "Тип вопроса",
      dataIndex: "type",
      render: (type: QuestionType) => (
        <ColoredSpan color="#98a4bc">{typeQuestion[type]}</ColoredSpan>
      ),
    },
    {
      title: "Связь с элементом разметки",
      dataIndex: "entity",
      render: (entity: IEntity) =>
        entity && <EntityTag color={typeColor[entity.type]} {...entity} />,
    },
  ];

  /**
   * Добавить новый вопрос
   * @param values Значения формы
   */
  const handleAddQuestion = async (values: any) => {
    try {
      const question = await QuestionsAPI.add(vacancy_id, values);
      if (question) {
        dispatch(addQuestion(question));
        setIsVisible(false);
        form.resetFields();
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleStartInterview = async () => {
    if (!vacancy) return;
    try {
      const settings = await InterviewSettingsAPI.update(vacancy_id, { status: "running" });
      if (settings) {
        dispatch(updateVacancy({ ...vacancy, interview_status: settings.status }));
        history.push(`/vacancies`);
      }
      console.log("settings :>> ", settings);
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const options = resumes.map((item) => ({ label: item.name, value: item.id }));

  if (vacancy && ["running", "ended"].includes(vacancy.interview_status)) {
    return (
      <ContentWrapper
        title={
          <>
            Аудиоинтервью для вакансии: <HighLight color="#5061DC">{vacancy?.name}</HighLight>
          </>
        }
        icon={<Voice set="light" primaryColor="#98A4BC" size={20} />}
        onBack={() => null}
      >
        <WhiteAroundBlock>
          <InfoMessage style={{ width: 500, margin: "2rem auto" }} align="center">
            Веб-форма опросника для аудиоинтевью успешно создана. Если вы не отправили ссылку на
            опросник электронным письмом, а скопировали ее в буфер, не забудьте поделиться ею с
            кандидатами. Появление отчетов по итогам анализа аудиозаписей вы можете на странице
            вакансии.
          </InfoMessage>
        </WhiteAroundBlock>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper
      title={
        <>
          Аудиоинтервью для вакансии: <HighLight color="#5061DC">{vacancy?.name}</HighLight>
        </>
      }
      icon={<Voice set="light" primaryColor="#98A4BC" size={20} />}
      onBack={() => null}
      extra={[
        <StyledSelect
          style={{ minWidth: 200 }}
          key="select_resume"
          radius={4}
          options={options}
          value={resumeId}
          placeholder="Выберите резюме"
          onChange={(resumeId: unknown) => setResumeId(resumeId as string)}
        />,
      ]}
      footerContent={
        <FooterContent>
          <LegendMessage>
            Вопросы сформированы на основе элементов вакансии, помеченных как приоритетные. Вы
            можете отредактировать вопросы, изменить их количество а также настроить другие
            параметры.
          </LegendMessage>
          <FooterControl>
            <Button size="large" type="default" onClick={() => setIsSettingsModalVisible(true)}>
              Настройки
            </Button>
            <Button size="large" type="primary" onClick={handleStartInterview}>
              Запустить
            </Button>
          </FooterControl>
        </FooterContent>
      }
    >
      <TableWrapper
        dataSource={questions}
        columns={columns}
        gridTemplateColumns="50px 1fr 170px 350px"
      />
      <Button block type="link" icon={<PlusCircleOutlined />} onClick={() => setIsVisible(true)}>
        Добавить вопрос
      </Button>
      <Modal
        visible={isVisible}
        title=""
        closable={false}
        onCancel={() => setIsVisible(false)}
        onOk={form.submit}
      >
        <Form form={form} onFinish={handleAddQuestion}>
          <Form.Item name="text">
            <TextArea placeholder="Введите текст вопроса" showCount maxLength={600} />
          </Form.Item>
        </Form>
      </Modal>
      <AudioInterviewSettingsModal
        vacancyId={vacancy_id}
        isVisible={isSettingsModalVisible}
        setVisible={setIsSettingsModalVisible}
      />
    </ContentWrapper>
  );
};

export default AudioInterviewQuestions;
