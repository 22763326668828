import Modal from "antd/lib/modal";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border: 1px solid #f7faff;
    box-sizing: border-box;
    box-shadow: 0px 24px 40px -15px rgba(92, 102, 115, 0.3);
    border-radius: 16px;
  }
`;
