import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import VacancyAPI from "../api/vacancies.api";
import { IEntity } from "./entities.reducer";
import { InterviewSettingsStatus } from "./interview.types";

export type VacancyStatus = "waiting" | "parsing" | "on_moderation" | "done";

export interface IVacancy {
  id: string;
  name: string;
  interview_status: InterviewSettingsStatus;
  position_id: string;
  entities: IEntity[];
  resume_count: number;
  answers_count?: number;
  status: VacancyStatus;
  is_archived: boolean;
  link: string;
  is_available: boolean;
  created_at: Date;
}

const initialState: IVacancy[] = [];

export const fetchVacancies = createAsyncThunk("vacancies/fetch", async () => {
  const vacancies = await VacancyAPI.fetch();
  return vacancies;
});

const vaccanciesSlice = createSlice({
  name: "vacancies",
  initialState,
  reducers: {
    setVacancies(_, { payload }: PayloadAction<IVacancy[]>) {
      return payload;
    },
    addVacancy(state, { payload }: PayloadAction<IVacancy>) {
      return [...state, payload];
    },
    updateVacancy(state, { payload }: PayloadAction<IVacancy>) {
      return state.map((item) => (item.id === payload.id ? payload : item));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVacancies.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer } = vaccanciesSlice;

export const { setVacancies, updateVacancy, addVacancy } = actions;

export default reducer;
