import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuditAPI } from "../api/audit.api";
import { IEntity } from "./entities.reducer";

export type TaskType = "resume" | "vacancy";

export interface ITask {
  id: string;
  company_name: string;
  completed_at: Date;
  created_at: Date;
  entities: IEntity[];
  type: TaskType;
  text: string;
}

const initialState: ITask[] = [];

export const fetchTasks = createAsyncThunk("tasks/fetch", async () => {
  const tasks = await AuditAPI.fetch();
  return tasks;
});

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasks(_, { payload }) {
      return payload;
    },
    toggleDisabled(state, { payload }: { payload: { task_id: string; entity_id: string } }) {
      const indexTask = state.findIndex((task) => task.id === payload.task_id);
      const indexEntity = state[indexTask].entities.findIndex(
        (entity) => entity.entity_id === payload.entity_id
      );
      const entity = state[indexTask].entities[indexEntity];
      state[indexTask].entities[indexEntity] = { ...entity, is_disabled: !entity.is_disabled };
      return state;
    },
    removeTask(state, { payload }) {
      return state.filter((item) => item.id !== payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTasks.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer } = tasksSlice;

export const { setTasks, removeTask, toggleDisabled } = actions;

export default reducer;
