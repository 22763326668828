import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEntity } from "./entities.reducer";
import ResumesAPI from "../api/resumes.api";

export type ResumeStatus = "waiting" | "parsing" | "on_moderation" | "done";
export interface IResume {
  id: string;
  name: string;
  email: string;
  is_selected: boolean;
  vacancy_id: string;
  entities: IEntity[];
  status: ResumeStatus;
  age: number;
  rating: number;
  source_link: string;
  file_id: string;
  created_at: Date;
  interview_link_id?: string;
}

const initialState: IResume[] = [];

export const fetchResumes = createAsyncThunk("resumes/fetch", async (vacancy_id: string) => {
  const resumes = await ResumesAPI.fetch(vacancy_id);
  return resumes;
});

const resumesSlice = createSlice({
  name: "resumes",
  initialState,
  reducers: {
    setResumes(_, { payload }: PayloadAction<IResume[]>) {
      return payload;
    },
    addResumes(state, { payload }: PayloadAction<IResume[]>) {
      return state.concat(payload);
    },
    updateResume(state, { payload }: PayloadAction<IResume>) {
      return state.map((item) => (item.id === payload.id ? payload : item));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResumes.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer } = resumesSlice;

export const { setResumes, updateResume, addResumes } = actions;

export default reducer;
