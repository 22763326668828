import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import PositionsAPI from "../api/positions.api";

export type EntityType = "task" | "instrument" | "position";

export interface IEntity {
  entity_id: string;
  name: string;
  type: EntityType;
  percentage: number;
  is_priority?: boolean;
  is_disabled?: boolean;
}

export const fetchEntitiesByPositionId = createAsyncThunk(
  "entities/fetchEntitiesByPositionId",
  async (id: string) => {
    const entities = await PositionsAPI.fetchEntitiesById(id);
    return entities;
  }
);

export const initialState: IEntity[] = [];

const entitiesSlice = createSlice({
  name: "entities",
  initialState,
  reducers: {
    setEntities(_, action: PayloadAction<IEntity[]>) {
      return action.payload;
    },
    updateEntity(state, { payload }: PayloadAction<IEntity>) {
      return state.map((item) => (item.entity_id === payload.entity_id ? payload : item));
    },
    toggleDisabled(state, { payload }) {
      return state.map((item) =>
        item.entity_id === payload ? { ...item, is_disabled: !item.is_disabled } : item
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntitiesByPositionId.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer } = entitiesSlice;

export const { setEntities, updateEntity, toggleDisabled } = actions;

export default reducer;
