import Button from "antd/lib/button";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
  InterviewFooter,
  InterviewHeader,
  InterviewTitle,
  InterviewWrapper,
} from "./interview.styled";
import { AudioOutlined } from "@ant-design/icons";

import { RootState } from "../../../store";
import InterviewProgress from "../../../components/interview/InterviewProgress";
import InterviewQuestion from "../../../components/interview/InterviewQuestion";
import { InterviewAPI } from "../../../api/interview.api";
import { message } from "antd";

type RouteParams = {
  interview_link_id: string;
  page: string;
};

const InterviewProccess: React.FC<RouteComponentProps<RouteParams>> = ({ match, history }) => {
  const { interview_link_id } = match.params;
  const page = +match.params.page;
  const { questions } = useSelector((state: RootState) => state);
  const question = page >= 0 && page < questions.length ? questions[page] : questions[0];

  const handleMoveNext = () => {
    history.push(`/interview/${interview_link_id}/${page + 1}`);
  };

  const handleMoveBack = () => {
    history.goBack();
  };

  const handleSubmitAnswers = async () => {
    try {
      const answers = questions.map((item) => ({ question_id: item.id, file_id: item.answer }));
      const response = await InterviewAPI.saveAnswers(interview_link_id, answers);
      if (response) {
        history.push(`/interview/${interview_link_id}/success`);
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  /**
   * Возвращаем статус кнопки Далее
   * @returns boolean
   */
  const isNextDisabled = () => {
    // Если нет вопросов
    if (questions.length === 0) return true;
    // Если нет ответа на текущий вопрос
    if (!question?.answer) return true;
  };

  const isLastQuestion = questions.length === page + 1;

  return (
    <InterviewWrapper vertical>
      <InterviewHeader>
        <InterviewTitle>
          <AudioOutlined /> Аудиоинтервью
        </InterviewTitle>
        <InterviewProgress questions={questions} />
      </InterviewHeader>
      {question && <InterviewQuestion question={question} />}
      <InterviewFooter>
        <Button size="large" type="default" onClick={handleMoveBack}>
          Назад
        </Button>
        {isLastQuestion ? (
          <Button
            size="large"
            type="primary"
            disabled={isNextDisabled()}
            onClick={handleSubmitAnswers}
          >
            Завершить
          </Button>
        ) : (
          <Button size="large" type="primary" disabled={isNextDisabled()} onClick={handleMoveNext}>
            Далее
          </Button>
        )}
      </InterviewFooter>
    </InterviewWrapper>
  );
};

export default InterviewProccess;
