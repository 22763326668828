import styled from "styled-components";
import Preloader from "./Preloader";

const ProcessingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    max-width: 500px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #5b6673;
  }
`;

const Processing = () => {
  return (
    <ProcessingWrapper>
      <Preloader />
      <p>Пожалуйста подождите, выполняется обработка данных. Это может занять некоторое время.</p>
    </ProcessingWrapper>
  );
};

export default Processing;
