import styled from "styled-components";

export const TransferButton = styled.button`
  display: inline-block;
  width: 100%;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #98a4bc;
  background-color: transparent;
  border: 0;
  padding: 15px 0 10px;
  cursor: pointer;
`;
