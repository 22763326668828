import { ColumnsType } from "antd/lib/table";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Folder } from "react-iconly";
import moment from "moment";

import ContentWrapper from "../../components/ContentWrapper";
import TableWrapper from "../../components/TableWrapper";
import { ColoredSpan } from "../../shared/text.styled";
import { RootState } from "../../store";
import { fetchTasks, ITask, TaskType } from "../../store/tasks.reducer";
import { typeTask } from "../../helpers/typeTask";
import { Link } from "react-router-dom";

interface ITaskColumns extends ITask {
  tasks_count: number;
  tools_count: number;
}

const Moderation = () => {
  const dispatch = useDispatch();
  const tasks = useSelector((state: RootState) => state.tasks);

  useEffect(() => {
    tasks.length === 0 && dispatch(fetchTasks());
  }, [dispatch, tasks.length]);

  const data = useMemo(
    () =>
      tasks.reduce<ITaskColumns[]>((acc, task) => {
        // Задаем дефолтные значения для элементов связей
        let tasks_count = 0;
        let tools_count = 0;
        // Считаем элементы по типу и приоритетности за один цикл
        for (const entity of task.entities) {
          if (entity.type === "task") {
            tasks_count++;
          }
          if (entity.type === "instrument") {
            tools_count++;
          }
        }

        return acc.concat({ ...task, tasks_count, tools_count });
      }, []),
    [tasks]
  );

  const columns: ColumnsType<ITaskColumns> = [
    {
      title: "Название компании",
      dataIndex: "company_name",
      render: (company_name: string, task) => {
        return <Link to={`/moderation/${task.id}`}>{company_name}</Link>;
      },
    },
    {
      title: "Тип",
      dataIndex: "type",
      render: (type: TaskType) => {
        return <ColoredSpan color="#21D996">{typeTask[type]}</ColoredSpan>;
      },
    },
    {
      title: "Задачи",
      dataIndex: "tasks_count",
      render: (tasks_count: number) => <ColoredSpan color="#98A4BC">{tasks_count}</ColoredSpan>,
    },
    {
      title: "Инструменты",
      dataIndex: "tools_count",
      render: (tools_count: number) => <ColoredSpan color="#98A4BC">{tools_count}</ColoredSpan>,
    },
    {
      title: "Загружено",
      dataIndex: "created_at",
      sorter: (a, b) => (moment(a.created_at) > moment(b.created_at) ? 1 : -1),
      render: (created_at) => moment(created_at).format("DD.MM HH:mm"),
    },
    {
      title: "Разметка получена",
      dataIndex: "completed_at",
      sorter: (a, b) => (moment(a.completed_at) > moment(b.completed_at) ? 1 : -1),
      render: (completed_at) => moment(completed_at).format("DD.MM HH:mm"),
    },
    {
      title: "",
      key: "action",
    },
  ];

  return (
    <ContentWrapper
      title="Задачи на проверку"
      icon={<Folder set="light" primaryColor="#98A4BC" size={20} />}
      onBack={() => null}
    >
      <TableWrapper
        dataSource={data}
        columns={columns}
        gridTemplateColumns="1fr 150px 100px 150px 200px 200px 50px"
      />
    </ContentWrapper>
  );
};

export default Moderation;
