import styled from "styled-components";

export const WhiteAroundBlock = styled.div`
  background: #ffffff;
  border: 1px solid #dae1f2;
  border-radius: 12px;
  padding: 16px;
`;

export const ScrolledBlock = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  overflow: auto;
`;
