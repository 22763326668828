import styled from "styled-components";
import { WhiteAroundBlock } from "../../../shared/block.styled";
export const InterviewWrapper = styled.div<{ vertical?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  justify-content: center;
  align-items: center;
`;
export const InterviewContent = styled.div<{
  align?: string;
  width?: string;
  horizontal?: boolean;
}>`
  background: #f7faff;
  box-shadow: 10px 16px 30px -10px rgba(92, 102, 115, 0.3);
  border-radius: 12px;
  width: ${({ width = "530px" }) => width};
  max-width: 1140px;
  padding: 20px 40px;
  text-align: ${({ align = "left" }) => align};
  color: #3a4a61;
  font-size: 18px;
  line-height: 22px;
  display: ${({ horizontal }) => (horizontal ? "flex" : "block")};
  margin-bottom: 1.5rem;
`;

export const InterviewHeader = styled.div`
  width: 100%;
  max-width: 1140px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const InterviewFooter = styled.div`
  width: 100%;
  max-width: 1140px;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  button {
    margin-left: 1rem;
    min-width: 160px;
  }
`;

export const InterviewTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #98a4bc;
`;
export const InterviewQuestionText = styled(WhiteAroundBlock)`
  height: 300px;
  width: 100%;
`;
export const InterviewQuestionWrapper = styled.div``;

export const Smile = styled.div`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
`;
