import { useCallback, useEffect, useState } from "react";
import VacancyAPI from "../api/vacancies.api";

export const useVacanciesStatus = () => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  const poll = useCallback(async () => {
    try {
      const data = await VacancyAPI.fetch();
      const doneCount = data.filter((item) => item.status === "done");
      if (data.length === doneCount.length) {
        setIsAvailable(true);
        timerId && clearTimeout(timerId);
      } else {
        setTimerId(setTimeout(poll, 5000));
      }
    } catch (error) {
      timerId && clearTimeout(timerId);
    }
  }, [timerId]);

  useEffect(() => {
    !timerId && poll();
    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [poll, timerId]);

  return { isAvailable };
};
