import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import QuestionsAPI from "../api/questions.api";
import { IEntity } from "./entities.reducer";

export type QuestionType =
  | "caliber"
  | "task_to_instrument"
  | "position_to_instrument"
  | "position_to_task";

export interface IQuestion {
  id: string;
  text: string;
  type: QuestionType;
  entity: IEntity;
  is_enabled: boolean;
  answer?: string;
}

export const fetchQuestions = createAsyncThunk<IQuestion[], { vacancy_id: string; params?: any }>(
  "questions/fetch",
  async (userData) => {
    const { vacancy_id, params } = userData;
    const questions = await QuestionsAPI.fetch(vacancy_id, { ...params });
    return questions;
  }
);

export const initialState: IQuestion[] = [];

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setQuestions(_, action: PayloadAction<IQuestion[]>) {
      return action.payload;
    },
    updateQuestion(state, { payload }: PayloadAction<IQuestion>) {
      return state.map((item) => (item.id === payload.id ? payload : item));
    },
    addQuestion(state, { payload }: PayloadAction<IQuestion>) {
      return [...state, payload];
    },
    setAnswer(state, { payload }: PayloadAction<{ id: string; answer?: string }>) {
      return state.map((q) => (q.id === payload.id ? { ...q, answer: payload.answer } : q));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestions.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer } = questionsSlice;

export const { setQuestions, updateQuestion, addQuestion, setAnswer } = actions;

export default reducer;
