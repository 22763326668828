import Button from "antd/lib/button";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import { useEffect, useMemo, useState } from "react";
import { Folder } from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import VacancyAPI from "../api/vacancies.api";

import ContentWrapper from "../components/ContentWrapper";
import Processing from "../components/Processing";
import Transfer from "../components/transfer/Transfer";
import { HighLight } from "../shared/text.styled";
import { RootState } from "../store";
import { fetchEntitiesByPositionId, IEntity } from "../store/entities.reducer";
import { fetchVacancies, updateVacancy } from "../store/vacancies.reducer";

const FooterControl = styled.div`
  text-align: right;
  button {
    margin-left: 10px;
  }
`;

const { confirm } = Modal;

type RouteParams = {
  vacancy_id: string;
};

const VacancyMapping: React.FC<RouteComponentProps<RouteParams>> = ({ match, history }) => {
  const dispatch = useDispatch();
  const { vacancy_id } = match.params;
  const [values, setValues] = useState<IEntity[]>([]);
  const { entities, vacancies } = useSelector((state: RootState) => state);
  const vacancy = vacancies.find((v) => v.id === vacancy_id);

  /**
   * Добавляем сущности вакансии в список выбранных
   */
  useEffect(() => {
    if (!vacancy) return;
    vacancy.entities.length > 0 && setValues(vacancy?.entities);
  }, [vacancy]);

  /**
   * Получаем сущности для позиции вакансии
   */
  useEffect(() => {
    if (!vacancy) return;
    dispatch(fetchEntitiesByPositionId(vacancy.position_id));
  }, [dispatch, vacancy]);

  /**
   * Подгружаем вакансии если их нет
   */
  useEffect(() => {
    vacancies.length === 0 && dispatch(fetchVacancies());
  }, [dispatch, vacancies.length]);

  /**
   * Добавляем дропнутый элемент в стейт
   */
  const handleDrop = (item: IEntity) => {
    const { entity_id, type, name, percentage } = item;
    setValues((prev) => prev.concat({ entity_id, type, name, percentage }));
  };

  // Отчищаем список выбранных элементов
  const handleReset = () => setValues([]);

  // Фильтруем элементы которые уже были выбраны
  const filteredEntities = useMemo(() => {
    // Получаем список айдишников выбранных элементов
    const ids = values.map((item) => item.entity_id);
    // Возвращаем список элементов без выбранных
    return entities.filter((item) => !ids.includes(item.entity_id));
  }, [values, entities]);

  /**
   * Обновляем элементы вакансии
   */
  const handleSaveVacancy = async () => {
    try {
      const vacancy = await VacancyAPI.update(vacancy_id, {
        entities: values,
      });
      dispatch(updateVacancy(vacancy));
      history.push(`/vacancies`);
    } catch (error: any) {
      console.log("error :>> ", error);
    }
  };

  // Удаляем выбранный элемент из списка
  const handleRemove = (id: string) => {
    // Проверяем есть ли в полном списке сущностей данный элемент
    const isIdExists = entities.some((item) => item.entity_id === id);
    if (isIdExists) {
      // Если есть, просто удаляем из списка выбранных элементов
      setValues(values.filter((v) => v.entity_id !== id));
    } else {
      // Если нет, то делаем текущий элемент выключенным
      setValues(
        values.map((item) =>
          item.entity_id === id ? { ...item, is_disabled: !item.is_disabled } : item
        )
      );
    }
  };

  // Назначаем элемент приоритетным
  const handleSetPriority = (id: string) => {
    // Считаем количество приоритетных элементов
    const priorityCount = values.filter((v) => v.is_priority && v.entity_id !== id).length;
    if (priorityCount < 3) {
      setValues(
        values.map((item) =>
          item.entity_id === id ? { ...item, is_priority: !item.is_priority } : item
        )
      );
    } else {
      message.warn("Вы можете выбрать не более 3-х");
    }
  };

  /**
   * Обрабатываем клик по кнопке "Назад"
   */
  const handleBack = () =>
    confirm({
      title: "Указанное действие приведет к потере несохраненных данных. Всё равно продолжить?",
      onOk() {
        history.push("/vacancy");
      },
    });

  // Добавляем все элементы в список выбранных
  const handleAppend = (entites: IEntity[]) => setValues((prev) => prev.concat(entites));

  if (vacancy?.status !== "done") {
    return <Processing />;
  }

  return (
    <ContentWrapper
      title={
        <>
          Разметка вакансии: <HighLight color="#5B6673">{vacancy?.name}</HighLight>
        </>
      }
      icon={<Folder set="light" primaryColor="#98A4BC" size={20} />}
      onBack={() => null}
      footerContent={
        <FooterControl>
          <Button size="large" type="default" onClick={handleBack}>
            Назад
          </Button>
          <Button
            size="large"
            type="primary"
            disabled={values.length === 0}
            onClick={handleSaveVacancy}
          >
            Сохранить
          </Button>
        </FooterControl>
      }
    >
      <Transfer
        dataSource={filteredEntities}
        values={values}
        onDrop={handleDrop}
        onReset={handleReset}
        onAppend={handleAppend}
        onRemove={handleRemove}
        onDoubleClick={handleSetPriority}
      />
    </ContentWrapper>
  );
};

export default VacancyMapping;
