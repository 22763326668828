import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { whoami } from "../api/auth.api";
import { login } from "../store/auth.reducer";

/**
 * Хук проверки авторизации
 */
export const useAuth = () => {
  const dispatch = useDispatch();
  // Состояние проверки, по умолчанию включено
  const [isChecking, setIsChecking] = useState(true);
  // Асинхронная функция проверки
  const verifyAuth = useCallback(async () => {
    // Получаем токен
    const token = localStorage.getItem("cvilla_user_token");
    try {
      if (token) {
        // Если токен есть, получаем данные для юзера
        const auth = await whoami();
        // Если данные получены, записываем в стейт
        if (auth) {
          dispatch(login(auth));
        }
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      // В любом случае отключаем проверку
      setIsChecking(false);
    }
  }, [dispatch]);

  useEffect(() => {
    verifyAuth();
  }, [verifyAuth]);

  return {
    isChecking,
  };
};
