import Select from "antd/lib/select";
import Dragger from "antd/lib/upload/Dragger";
import styled from "styled-components";

export const StyledSelect = styled(Select)<{ radius?: number }>`
  width: 100%;
  margin: 10px 0;
  .ant-select-selector {
    border-radius: ${({ radius = 20 }) => radius}px !important;
    .ant-select-selection-item,
    .ant-select-selection-placeholder,
    .ant-select-selection-search {
      color: #98a4bc;
      font-size: 15px;
      font-weight: 400;
      padding-left: 26px;
    }
  }
  .ant-select-arrow {
    left: 12px;
    top: 16px;
  }
  .ant-select-clear {
    right: ${({ radius = 20 }) => (radius < 10 ? 10 : radius)}px;
  }
`;

export const Uploader = styled(Dragger)`
  padding: 30px 0;
  background: #ffffff !important;
  border: 1px dashed #98a4bc !important;
  border-radius: 16px !important;
  margin: 1.25rem 0;
`;
