import { DragSourceMonitor, useDrag } from "react-dnd";
import { IEntity } from "../../store/entities.reducer";
import EntityTag from "../EntityTag";

interface TransferSourceItemProps extends IEntity {
  color: string;
}

const TransferSourceItem: React.FC<TransferSourceItemProps> = (props) => {
  const [{ opacity }, drag] = useDrag(() => ({
    type: props.type,
    item: props,
    collect: (monitor: DragSourceMonitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));
  return <EntityTag ref={drag} {...props} opacity={opacity} />;
};

export default TransferSourceItem;
