import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuth {
  id: string;
  name: string;
  email: string;
  role: "admin" | "analyst" | "user" | "guest";
  company_id: string;
  token: string | null;
  avatar?: string;
  isLoggedIn: boolean;
  isLogging: boolean;
}

const initialState: IAuth = {
  id: "",
  name: "",
  email: "",
  company_id: "",
  role: "guest",
  token: localStorage.getItem("cvilla_user_token"),
  isLoggedIn: false,
  isLogging: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logging(state, action: PayloadAction<boolean>) {
      return { ...state, isLogging: action.payload };
    },
    login(_, action: PayloadAction<IAuth>) {
      return { ...action.payload, isLoggedIn: true };
    },
    logout() {
      return initialState;
    },
  },
});

const { actions, reducer } = authSlice;

export const { login, logout, logging } = actions;

export default reducer;
