import React, { useState } from "react";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import TransferSource from "./TransferSource";
import TransferTarget from "./TransferTarget";
import { IEntity } from "../../store/entities.reducer";
import { RetweetOutlined } from "@ant-design/icons";
import Button from "antd/lib/button";
type TransferProps = {
  dataSource: IEntity[];
  values: IEntity[];
  onDrop: (item: IEntity) => void;
  onRemove: (id: string) => void;
  onReset?: () => void;
  onAppend?: (entites: IEntity[]) => void;
  onDoubleClick?: (id: string) => void;
  rowId?: keyof IEntity;
};

const TransferWrapper = styled.div<{ isReverse: boolean }>`
  display: flex;
  flex-direction: ${({ isReverse }) => (isReverse ? "row-reverse" : "row")};
  & > div:not(:nth-of-type(2)) {
    flex-basis: 100%;
  }
`;

const TransferReverse = styled.div`
  text-align: center;
  align-self: center;
  padding: 10px;
`;

const Transfer: React.FC<TransferProps> = ({
  dataSource,
  values,
  onDrop,
  onReset,
  onAppend,
  onRemove,
  onDoubleClick,
  rowId = "entity_id",
}) => {
  const [isReverse, setIsReverse] = useState(false);
  return (
    <TransferWrapper isReverse={isReverse}>
      <DndProvider backend={HTML5Backend}>
        <TransferSource rowId={rowId} dataSource={dataSource} onAppend={onAppend} />
        <TransferReverse>
          <Button
            type="default"
            icon={<RetweetOutlined />}
            onClick={() => setIsReverse(!isReverse)}
          />
        </TransferReverse>
        <TransferTarget
          values={values}
          onDrop={onDrop}
          onRemove={onRemove}
          onReset={onReset}
          onDoubleClick={onDoubleClick}
          rowId={rowId}
        />
      </DndProvider>
    </TransferWrapper>
  );
};

export default React.memo(Transfer);
