import React, { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal";
import styled from "styled-components";
import Button from "antd/lib/button";
import Input from "antd/lib/input";

import { InterviewSettingsAPI } from "../api/interview.api";
import { CommunicationSkill, IInterviewSettings } from "../store/interview.types";
import ThreePoints from "../components/settings/ThreePoints";
import Checkbox from "../components/Checkbox";
import { LegendMessage } from "../shared/text.styled";
import { message } from "antd";

const FooterControl = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0 15px;
  grid-template-areas: ". .";
  margin-top: 26px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border: 1px solid #f7faff;
    box-sizing: border-box;
    box-shadow: 0px 24px 40px -15px rgba(92, 102, 115, 0.3);
    border-radius: 16px;
  }
`;

const StyledTextArea = styled(TextArea)`
  border: 1px solid #dae1f2;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #3a4a61;
  padding: 12px;
`;

interface AudioInterviewSettingsModalProps {
  isVisible: boolean;
  setVisible: (boolean: boolean) => void;
  vacancyId: string;
}

const AudioInterviewSettingsModal: React.FC<AudioInterviewSettingsModalProps> = ({
  vacancyId,
  isVisible,
  setVisible,
}) => {
  const [settings, setSettings] = useState<IInterviewSettings>({
    vacancy_id: vacancyId,
    link_ttl: 10,
    time_limit: 2,
    communication_required: "mid",
    is_send_to_email: true,
    mail_subject: "",
    mail_body: "",
    status: "draft",
  });

  /**
   * Загружаем настройки
   */
  useEffect(() => {
    (async () => {
      try {
        const settings = await InterviewSettingsAPI.fetch(vacancyId);
        setSettings(settings);
      } catch (error) {
        message.error("Ошибка загрузки настроек интервью");
      }
    })();
  }, [vacancyId]);

  /**
   * Изменяем числовые значения
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    if (value) {
      setSettings({ ...settings, [e.target.name]: e.target.value });
    }
  };

  /**
   * Редактируем текстовые поля
   */
  const handleRewrite = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  /**
   * Изменяем требования к уровню коммуникаций
   * @param communication_required
   */
  const handleSlide = (communication_required: CommunicationSkill) => {
    setSettings({ ...settings, communication_required });
  };

  /**
   * Включаем/отключаем email уведомления
   * @param e
   */
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, [e.target.name]: e.target.checked });
  };

  const handleSave = async () => {
    try {
      const saved = await InterviewSettingsAPI.update(vacancyId, settings);
      if (saved) {
        setVisible(false);
      }
    } catch (error) {}
  };

  return (
    <StyledModal
      visible={isVisible}
      title={null}
      footer={null}
      closable={false}
      width={930}
      onCancel={() => setVisible(false)}
    >
      <div className="audio-interview-settings">
        <div className="audio-interview-settings__col">
          <p className="audio-interview-settings__col-title">Лимиты</p>
          <div className="audio-interview-settings__limits">
            <div className="audio-interview-settings__limit">
              <span>Время жизни опросника (календ. дней)</span>
              <Input
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                min={1}
                max={30}
                name="link_ttl"
                id="link_ttl"
                value={settings.link_ttl}
                onChange={handleChange}
              />
            </div>
            <div className="audio-interview-settings__limit">
              <span>Ограничение времени на один ответ (мин.)</span>
              <Input
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                min={1}
                max={10}
                name="time_limit"
                value={settings.time_limit}
                onChange={handleChange}
              />
            </div>
          </div>
          <p className="audio-interview-settings__col-title audio-interview-settings__col-title_second">
            Коммуникативные навыки
          </p>
          <ThreePoints value={settings.communication_required} onChange={handleSlide} />
          <LegendMessage>
            Уровень развития коммуникативных навыков, необходимый для работы в определенной
            профессиональной сфере. По умолчанию настройка соответствует характеру вакансии.
          </LegendMessage>
        </div>
        <div className="audio-interview-settings__col">
          <p className="audio-interview-settings__col-title">Настройки доступа кандидатов</p>
          <div className="audio-interview-settings__inline">
            <div className="audio-interview-settings__send-email">
              <Checkbox
                name="is_send_to_email"
                checked={settings.is_send_to_email}
                onChange={handleCheckbox}
              >
                Отправлять на email
              </Checkbox>
            </div>
          </div>
          <div className="audio-interview-settings__subject">
            <h2 className="audio-interview-settings__col-title audio-interview-settings__col-title_s">
              Тема сообщения
            </h2>
            <StyledTextArea
              value={settings.mail_subject}
              name="mail_subject"
              onChange={handleRewrite}
              disabled={!settings.is_send_to_email}
            />
          </div>
          <div className="audio-interview-settings__body">
            <h2 className="audio-interview-settings__col-title audio-interview-settings__col-title_s">
              Текст сообщения:
            </h2>
            <StyledTextArea
              value={settings.mail_body}
              name="mail_body"
              onChange={handleRewrite}
              rows={12}
              disabled={!settings.is_send_to_email}
            />
          </div>
          <FooterControl>
            <Button
              size="large"
              type="default"
              aria-label="Close"
              onClick={() => setVisible(false)}
            >
              Отменить
            </Button>
            <Button size="large" type="primary" onClick={handleSave}>
              Сохранить
            </Button>
          </FooterControl>
        </div>
      </div>
    </StyledModal>
  );
};

export default AudioInterviewSettingsModal;
