import { useCallback, useEffect, useRef, useState } from "react";
import Button from "antd/lib/button";
import { VolumeUp, VolumeOff } from "react-iconly";

type ReportPlayerProps = {
  fileId: string;
};
const ReportPlayer: React.FC<ReportPlayerProps> = ({ fileId }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const audio = useRef<HTMLAudioElement>(new Audio());

  useEffect(() => {
    if (!isPlaying && audio.current.currentTime > 0) {
      audio.current.pause();
    }
  }, [isPlaying]);

  const play = useCallback(() => {
    setIsLoading(false);
    audio.current.play();
  }, []);

  useEffect(() => {
    const player = audio.current;

    if (isPlaying) {
      player.src = process.env.REACT_APP_BACKEND + "/download/" + fileId;
      player.crossOrigin = "anonymous";
      player.load();
      setIsLoading(true);
      player.addEventListener("canplaythrough", play);
      player.addEventListener("pause", () => setIsPlaying(false));
      player.addEventListener("ended", () => setIsPlaying(false));
    }

    return () => {
      player.pause();
      player.removeEventListener("canplaythrough", play);
      player.removeEventListener("pause", () => setIsPlaying(false));
      player.removeEventListener("ended", () => setIsPlaying(false));
    };
  }, [fileId, isPlaying, play]);

  return (
    <Button
      type="link"
      loading={isLoading}
      onClick={() => setIsPlaying(!isPlaying)}
      icon={
        isPlaying ? (
          <VolumeOff set="light" primaryColor="#5061DC" />
        ) : (
          <VolumeUp set="light" primaryColor="#5061DC" />
        )
      }
    />
  );
};

export default ReportPlayer;
