import Avatar from "antd/lib/avatar";
import Popover from "antd/lib/popover";
import Tooltip from "antd/lib/tooltip";
import readableColor from "polished/lib/color/readableColor";
import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface NavigationProps {
  children: ReactNode;
  background?: string;
  activeColor?: string;
}

interface NavigationItemProps extends NavigationProps {
  icon: ReactNode;
  to: string;
  isActive?: boolean;
  isHovering?: boolean;
  isHidden?: boolean;
}

interface NavigationUserProps extends NavigationProps {
  name: string;
  role: string;
  isLoggedIn: boolean;
  avatar?: string;
  menu: ReactNode;
}

const NavigationItem = styled.div<Partial<NavigationItemProps>>`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin: 6px;
  cursor: pointer;
  text-align: center;
  border-radius: 12px;
  background-color: ${({ isHovering, isActive, activeColor }) =>
    isHovering || isActive ? activeColor : "transparent"};
`;

const NavigationUser = styled(Avatar)`
  border-radius: 12px;
  cursor: pointer;
`;

const NavigationWrapper = styled.div<NavigationProps>`
  display: inline-flex;
  background-color: ${({ background = "#ededed" }) => background};
  border-radius: 12px;
  margin: 5px;
  padding: 0 10px;
  align-content: center;
  justify-content: space-around;
  align-items: center;
`;

const NavigationItemIcon = styled(Link)<{ color?: string }>`
  display: inherit;
  svg {
    fill: ${({ color }) => color};
  }
`;

const Item: React.FC<NavigationItemProps> = ({
  icon,
  children,
  to,
  isActive,
  isHidden,
  activeColor = "#C0BBC5",
}) => {
  const [isHovering, setIsHovering] = useState(false);

  if (isHidden) return <></>;

  return (
    <Tooltip placement="bottom" color={activeColor} title={children}>
      <NavigationItem
        activeColor={activeColor}
        isHovering={isHovering}
        isActive={isActive}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <NavigationItemIcon
          to={to}
          color={isActive || isHovering ? readableColor(activeColor) : activeColor}
        >
          {icon}
        </NavigationItemIcon>
      </NavigationItem>
    </Tooltip>
  );
};

const User: React.FC<NavigationUserProps> = ({ name, avatar, menu, activeColor, children }) => {
  return (
    <Popover trigger={["click"]} placement="bottomRight" title={name} content={menu}>
      <NavigationUser
        src={avatar}
        shape="square"
        size={44}
        icon={children}
        style={{ backgroundColor: "#98A4BC" }}
      />
    </Popover>
  );
};

export interface INavigation extends React.FC<NavigationProps> {
  Item: typeof Item;
  User: typeof User;
}

const Navigation: INavigation = ({ children, activeColor, background }) => {
  const length = React.Children.count(children);

  if (length === 0) return <></>;

  const renderMenu = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...child.props, activeColor, background });
    } else {
      return child;
    }
  });

  return (
    <NavigationWrapper background={background} activeColor={activeColor}>
      {renderMenu}
    </NavigationWrapper>
  );
};

Navigation.Item = Item;
Navigation.User = User;

export default Navigation;
