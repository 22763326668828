import { IQuestion } from "../../store/questions.reducer";
import {
  InterviewProgressBar,
  InterviewProgressLabel,
  InterviewProgressLine,
  InterviewProgressLineItem,
  InterviewProgressMarks,
  InterviewProgressWrapper,
} from "./interview_progress.styled";

type InterviewProgressProps = {
  questions: IQuestion[];
};

const InterviewProgress: React.FC<InterviewProgressProps> = ({ questions }) => {
  return (
    <InterviewProgressWrapper>
      <InterviewProgressLabel>Прогресс</InterviewProgressLabel>
      <InterviewProgressBar>
        <InterviewProgressMarks>
          <span>0%</span>
          <span>100%</span>
        </InterviewProgressMarks>
        <InterviewProgressLine role="progressbar">
          {questions.map((item) => (
            <InterviewProgressLineItem key={item.id} active={!!item.answer} />
          ))}
        </InterviewProgressLine>
      </InterviewProgressBar>
    </InterviewProgressWrapper>
  );
};

export default InterviewProgress;
