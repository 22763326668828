import { Form, Input, Button, Typography, message, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import { RootState } from "../store";
import { logging, login } from "../store/auth.reducer";
import { signin } from "../api/auth.api";

import logo from "../assets/logo-2x.png";

const FormItem = styled(Form.Item)`
  .ant-input {
    border-color: #e0dbd1;
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
    }
    &:focus,
    &.ant-input-focused {
      border-color: #e0dbd1;
      box-shadow: none;
    }
  }
`;

const Username = styled(FormItem)`
  margin-bottom: 0;
  .ant-input {
    border-radius: 8px 8px 0 0;
    border-bottom: 0;
  }
`;

const Password = styled(FormItem)`
  .ant-input {
    border-radius: 0 0 8px 8px;
  }
`;

const LoginWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const LoginHeader = styled.div`
  img {
    margin-top: 60px;
  }
`;

const LoginFooter = styled.div`
  color: #636363;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding-bottom: 40px;
`;

const LoginContent = styled.div`
  min-height: 600px;
`;

const LoginForm = styled.div`
  background: #f7faff;
  box-shadow: 10px 16px 30px -10px rgba(92, 102, 115, 0.3);
  border-radius: 12px;
  width: 530px;
  padding: 15px 30px 5px;
`;

const LoginTitle = styled(Typography.Title)`
  text-align: center;
  margin: 10px 0 40px !important;
`;

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoggedIn, isLogging } = useSelector((state: RootState) => state.auth);

  if (isLoggedIn) {
    return <Redirect to="/vacancies" />;
  }

  const handleAuthorization = async (credentials: any) => {
    const { email, password } = credentials;
    try {
      dispatch(logging(true));
      const auth = await signin(email, password);
      // Проверяем наличие токена в ответе
      if (auth.token) {
        // Сохраняем токен в хранилище
        localStorage.setItem("cvilla_user_token", auth.token);
        // Сохраняем в стейт
        dispatch(login(auth));
        // Выводим сообщение
        message.success("Вы успешно вошли в систему");
        if (auth.role === "analyst") {
          history.push("/moderation");
        } else {
          history.push("/vacancies");
        }
        // Перекидываем в активные опросы
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      // Выключаем спиннер
      dispatch(logging(false));
    }
  };

  return (
    <LoginWrapper>
      <LoginHeader>
        <img src={logo} alt="" />
      </LoginHeader>
      <LoginContent>
        <LoginForm>
          <LoginTitle level={4}>Вход</LoginTitle>
          <Form
            layout="vertical"
            onFinish={handleAuthorization}
            initialValues={{ remember: true }}
            requiredMark={false}
          >
            <Username name="email" required>
              <Input size="large" type="email" placeholder="E-mail" />
            </Username>

            <Password name="password" required>
              <Input.Password size="large" visibilityToggle={false} placeholder="Пароль" />
            </Password>

            <Form.Item name="remember_me">
              <Checkbox checked>Запомнить меня</Checkbox>
            </Form.Item>

            <Form.Item style={{ marginBottom: 30 }}>
              <Button block size="large" type="primary" htmlType="submit" loading={isLogging}>
                {isLogging ? "Выполняется вход..." : "Войти"}
              </Button>
            </Form.Item>
          </Form>
        </LoginForm>
      </LoginContent>
      <LoginFooter>
        <p>&copy; Университет НТИ &laquo;20.35&raquo;, 2021</p>
      </LoginFooter>
    </LoginWrapper>
  );
};

export default Login;
