import React from "react";
import styled from "styled-components";

import { typeColor } from "../helpers/typeColor";
import { IReport } from "../store/report.types";
import CommunicationVerify from "./CommunicationVerify";
import EntityTag from "./EntityTag";
import ReportPlayer from "./report/ReportPlayer";

const ReportBodyItem = styled.div`
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 3px 26px -5px rgba(33, 31, 31, 0.1);
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 650px 200px;
  padding: 5px 0 10px;
`;

const ReportBodyItemName = styled.div`
  color: #5061dc;
  padding: 16px;
  cursor: pointer;
`;
const ReportBodyItemLast = styled.div`
  padding: 16px;
  display: flex;
`;
const ReportBodyItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 275px 275px 100px;
`;
const ReportBodyItemCol = styled.div`
  padding: 5px 5px 0;
`;

const ReportQuestion = styled.div`
  background: #f7faff;
  border: 1px solid #dae1f2;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 12px;
  line-height: 16px;
  color: #5b6673;
  padding: 12px;
`;

type ReportItemProps = {
  item: IReport;
  index: number;
  isActive: boolean;
  onClick: (item: number) => void;
};

function ReportItem({ item, onClick, index, isActive }: ReportItemProps) {
  const isPassed = item.questions.every((item) => item.answer);
  return (
    <ReportBodyItem>
      <ReportBodyItemName onClick={() => onClick(index)}>{item.resume_name}</ReportBodyItemName>
      <ReportBodyItemWrapper>
        {item.questions.map(
          (item) =>
            isActive && (
              <React.Fragment key={item.id}>
                <ReportBodyItemCol>
                  <ReportQuestion>{item.text}</ReportQuestion>
                </ReportBodyItemCol>
                <ReportBodyItemCol>
                  {item.entity && (
                    <EntityTag color={typeColor[item.entity.type]} {...item.entity} />
                  )}
                </ReportBodyItemCol>
                <ReportBodyItemCol>
                  {item.answer && <ReportPlayer fileId={item.answer} />}
                </ReportBodyItemCol>
              </React.Fragment>
            )
        )}
      </ReportBodyItemWrapper>
      <ReportBodyItemLast>
        {isActive && isPassed && <CommunicationVerify is_according={item.is_according} />}
      </ReportBodyItemLast>
    </ReportBodyItem>
  );
}

export default ReportItem;
