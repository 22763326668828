import React, { useRef } from "react";
import styled from "styled-components";
import { CommunicationSkill } from "../../store/interview.types";

import pointerImage from "../../assets/triangle.svg";

type ThreePointsProps = {
  value: CommunicationSkill;
  onChange: (value: CommunicationSkill) => void;
};

const ThreePointsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const ThreePointsBar = styled.div`
  display: flex;
  flex: 1;
`;

const ThreePointsBarItem = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 24px;
  border: 1px solid #8564ff;
  border-radius: 2px;
  background-color: ${({ isActive }) => (isActive ? "#8564ff" : "transparent")};
`;

const ThreePointsBarDivider = styled.div`
  width: 6px;
`;

const ThreePointsPointer = styled.div<{ align: string }>`
  display: flex;
  justify-content: ${({ align }) => align};
  img {
    cursor: grab;
  }
`;

const ThreePointsLabels = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ThreePointsLabelItem = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? "#8564FF" : "#D8CDFF")};
`;

const ThreePoints: React.FC<ThreePointsProps> = ({ value, onChange }) => {
  const parent = useRef<HTMLDivElement>(null);

  const align = {
    weak: "flex-start",
    mid: "center",
    strong: "flex-end",
  };

  const labels = [
    { name: "Низкий", value: "weak" },
    { name: "Средний", value: "mid" },
    { name: "Высокий", value: "strong" },
  ];

  return (
    <ThreePointsWrapper ref={parent}>
      <ThreePointsBar data-testid="three-points-bar">
        <ThreePointsBarItem isActive={["mid", "strong"].includes(value)}></ThreePointsBarItem>
        <ThreePointsBarDivider />
        <ThreePointsBarItem isActive={["strong"].includes(value)}></ThreePointsBarItem>
      </ThreePointsBar>
      <ThreePointsPointer align={align[value]} data-testid="three-points-pointer">
        <img src={pointerImage} alt="Указатель" />
      </ThreePointsPointer>
      <ThreePointsLabels>
        {labels.map((item) => (
          <ThreePointsLabelItem
            role="button"
            key={item.value}
            isActive={item.value === value}
            onClick={() => onChange(item.value as CommunicationSkill)}
          >
            {item.name}
          </ThreePointsLabelItem>
        ))}
      </ThreePointsLabels>
    </ThreePointsWrapper>
  );
};

export default ThreePoints;
