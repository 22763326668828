import React from "react";
import styled from "styled-components";
import Tag from "antd/lib/tag";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { AudioOutlined } from "@ant-design/icons";

import VacancyAPI from "../api/vacancies.api";
import ContentWrapper from "../components/ContentWrapper";
import { HighLight } from "../shared/text.styled";
import { RootState } from "../store";
import { IReport } from "../store/report.types";
import { fetchVacancies } from "../store/vacancies.reducer";
import ReportItem from "../components/ReportItem";

type RouteParams = {
  vacancy_id: string;
};

const ReportHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 275px 275px 100px 200px;
  margin-left: 1rem;
`;

const ReportHeaderItem = styled.span`
  color: #3a4a61;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 5px;
`;

const ReportBody = styled.div``;

const Report: React.FC<RouteComponentProps<RouteParams>> = ({
  match,
  history,
}) => {
  const { vacancy_id } = match.params;
  const dispatch = useDispatch();
  const [reports, setReports] = useState<IReport[]>([]);
  const [activeKey, setActiveKey] = useState(0);
  const vacancies = useSelector((state: RootState) => state.vacancies);
  const vacancy = vacancies.find((v) => v.id === vacancy_id);

  useEffect(() => {
    vacancies.length === 0 && dispatch(fetchVacancies());
  }, [dispatch, vacancies.length]);

  useEffect(() => {
    (async () => {
      try {
        const reports = await VacancyAPI.getReport(vacancy_id);
        setReports(reports);
      } catch (error: any) {
        message.error(error.message);
      }
    })();
  }, [vacancy_id]);

  const handleClick = (index: number) => {
    setActiveKey(index);
  };

  return (
    <ContentWrapper
      title={
        <>
          Аудиоинтервью для вакансии:{" "}
          <HighLight color="#5061DC">{vacancy?.name}</HighLight>{" "}
          <Tag color="#5B6673">Отчет</Tag>
        </>
      }
      extra={<Link to="/vacancies">К списку вакансий</Link>}
      icon={<AudioOutlined style={{ color: "#98A4BC", fontSize: "1.3rem" }} />}
      onBack={() => null}
    >
      <ReportHeader>
        <ReportHeaderItem>Резюме</ReportHeaderItem>
        <ReportHeaderItem>Вопрос</ReportHeaderItem>
        <ReportHeaderItem>Элемент разметки</ReportHeaderItem>
        {/* <ReportHeaderItem>Аудио</ReportHeaderItem> */}
        <ReportHeaderItem>Коммуникативные навыки</ReportHeaderItem>
      </ReportHeader>
      <ReportBody>
        {reports
          .filter((item) => item.questions.length > 0)
          .map((item, i) => (
            <ReportItem
              key={i}
              index={i}
              isActive={i === activeKey}
              item={item}
              onClick={handleClick}
            />
          ))}
      </ReportBody>
    </ContentWrapper>
  );
};

export default Report;
