import React from "react";
import styled from "styled-components";
import { renderToStaticMarkup } from "react-dom/server";

type CheckboxProps = {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  value?: any;
  color?: string;
  size?: number;
  children?: React.ReactNode;
};

const CheckboxWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
`;

const CheckIcon: React.FC<{ size?: number; color?: string }> = ({
  size = 14,
  color = "#5061DC",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`M1 6.33325L${size / 2}.00169 10.3333L${size - 3} 4`}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Input = styled.input<{ checked?: boolean; color?: string; size?: number; bg: string }>`
  appearance: none;
  width: ${({ size = 14 }) => size}px;
  height: ${({ size = 14 }) => size}px;
  margin-right: 0.3rem;
  background-color: #fff;
  border: 1px solid ${({ color = "#5061dc" }) => color};
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  &::after {
    display: ${({ checked }) => (checked ? "block" : "none")};
    content: "";
    height: ${({ size = 14 }) => size - 4}px;
    width: ${({ size = 14 }) => size - 4}px;
    background-image: ${({ bg }) => bg};
    background-repeat: no-repeat;
    background-position: center center;
    margin: 1px;
  }
`;

const Label = styled.label`
  cursor: pointer;
`;

const Checkbox = React.forwardRef<HTMLDivElement, CheckboxProps>((props, ref) => {
  const size = props.size || 14;
  const svgString = encodeURIComponent(
    renderToStaticMarkup(<CheckIcon color={props.color} size={size} />)
  );
  const dataUri = `url('data:image/svg+xml,${svgString}')`;
  return (
    <CheckboxWrapper ref={ref}>
      <Input
        id={props.name}
        type="checkbox"
        name={props.name}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        color={props.color}
        size={size}
        bg={dataUri}
      />
      <Label htmlFor={props.name}>{props.children}</Label>
    </CheckboxWrapper>
  );
});

export default Checkbox;
