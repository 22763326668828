import styled from "styled-components";

export const FooterControl = styled.div`
  text-align: right;
  .ant-btn {
    margin-left: 15px;
  }
`;

export const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;
