import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAppState {
  isLoading: boolean;
}

const initialState: IAppState = {
  isLoading: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload };
    },
  },
});

const { actions, reducer } = appSlice;

export const { loading } = actions;

export default reducer;
