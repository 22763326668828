import axios from "axios";
import { IResume } from "../store/resumes.reducer";

export type ResumeFilter = {
  countries: number[];
  cities: number[];
  age_from: number;
  age_to: number;
};

const fetch = async (vacancy_id: string) => {
  try {
    const response = await axios.get<IResume[]>(`/vacancies/${vacancy_id}/resumes`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const add = async (
  vacancy_id: string,
  files: string[],
  sources: string[],
  filters?: ResumeFilter
) => {
  try {
    const response = await axios.post<IResume[]>(`/vacancies/${vacancy_id}/resumes`, {
      files,
      sources,
      filters,
    });
    return response.data;
  } catch (error) {}
};

const update = async (vacancy_id: string, resume_id: string, data: any) => {
  try {
    const response = await axios.patch<IResume>(
      `/vacancies/${vacancy_id}/resumes/${resume_id}`,
      data
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

type Location = {
  id: number;
  name: string;
  country_id?: number;
};

export type Locations = {
  countries: Location[];
  cities: Location[];
};

const locations = async () => {
  try {
    const response = await axios.get<Locations>(`/locations`);
    return response.data;
  } catch (error) {
    throw new Error("Нет данных по странам и городам");
  }
};

const ResumesAPI = { fetch, add, update, locations };

export default ResumesAPI;
