import { useEffect, useState } from "react";
import InputNumber from "antd/lib/input-number";

import ResumesAPI, { Locations } from "api/resumes.api";

import { FilterResumeSelect, FilterResumeWrapper } from "./FilterResume.styled";

type FilterResumeProps = {
  countries: number[];
  cities: number[];
  ageRange: [number, number];
  onChangeAgeRange: (age_from: number, age_to: number) => void;
  onChange: (countries: number[], cities: number[]) => void;
};

function FilterResume({
  countries,
  cities,
  ageRange,
  onChangeAgeRange,
  onChange,
}: FilterResumeProps) {
  const [locations, setLocations] = useState<Locations>({
    countries: [],
    cities: [],
  });

  useEffect(() => {
    (async () => {
      try {
        const locations = await ResumesAPI.locations();
        locations && setLocations(locations);
      } catch (error) {}
    })();
  }, []);

  const handleChangeCountry = (countries: unknown) => {
    onChange(countries as number[], cities);
  };

  const handleChangeCity = (cities: unknown) => {
    onChange(countries, cities as number[]);
  };

  const countriesOptions = locations.countries.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const citiesOptions = locations.cities
    .filter((item) => {
      if (countries.length > 0) {
        return countries.includes(item.country_id!);
      } else {
        return false;
      }
    })
    .map((item) => ({ label: item.name, value: item.id }));

  const handleChangeRangeFrom = (from: number) => {
    onChangeAgeRange(from, ageRange[1]);
  };

  const handleChangeRangeTo = (to: number) => {
    onChangeAgeRange(ageRange[0], to);
  };

  return (
    <FilterResumeWrapper>
      <FilterResumeSelect
        showSearch
        mode="multiple"
        placeholder="Страна"
        options={countriesOptions}
        value={countries}
        onChange={handleChangeCountry}
      />
      <FilterResumeSelect
        showSearch
        mode="multiple"
        placeholder="Город"
        options={citiesOptions}
        value={cities}
        onChange={handleChangeCity}
      />
      <InputNumber
        min={18}
        max={65}
        value={ageRange[0]}
        addonBefore="Возраст от"
        addonAfter="лет"
        onChange={handleChangeRangeFrom}
      />
      <InputNumber
        min={18}
        max={65}
        value={ageRange[1]}
        addonBefore="до"
        addonAfter="лет"
        onChange={handleChangeRangeTo}
      />
    </FilterResumeWrapper>
  );
}

export default FilterResume;
