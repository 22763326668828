import axios from "axios";
import { IEntity } from "../store/entities.reducer";
import { IReport } from "../store/report.types";
import { IVacancy } from "../store/vacancies.reducer";

const fetch = async (): Promise<IVacancy[]> => {
  try {
    const response = await axios(`/vacancies`);
    return response.data;
  } catch (error: any) {
    return [];
  }
};

const update = async (id: string, fields: Partial<IVacancy>): Promise<IVacancy> => {
  try {
    const response = await axios.patch(`/vacancies/${id}`, fields);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

type NewVacancy = {
  position_id: string;
  entities?: IEntity[];
  file_id?: string;
};

const add = async (vacancy: NewVacancy): Promise<IVacancy> => {
  try {
    const response = await axios.post(`/vacancies`, vacancy);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const getReport = async (vacancy_id: string): Promise<IReport[]> => {
  try {
    const response = await axios(`/vacancies/${vacancy_id}/report`);
    return response.data;
  } catch (error: any) {
    throw new Error("Не удалось получить отчет по выбранной вакансии");
  }
};

const VacancyAPI = { add, fetch, update, getReport };

export default VacancyAPI;
