import Button from "antd/lib/button";
import Input from "antd/lib/input";
import {
  Edit,
  MoreSquare,
  Folder,
  Hide,
  PaperDownload,
  Voice,
  Work,
  Show,
} from "react-iconly";
import message from "antd/lib/message";
import { ColumnsType } from "antd/lib/table";
import Tabs from "antd/lib/tabs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link, RouteComponentProps } from "react-router-dom";

import { RootState } from "../store";
import { InterviewSettingsStatus } from "../store/interview.types";
import {
  fetchVacancies,
  IVacancy,
  updateVacancy,
} from "../store/vacancies.reducer";
import { IEntity } from "../store/entities.reducer";
import VacancyAPI from "../api/vacancies.api";
import { ColoredSpan, LegendMessage } from "../shared/text.styled";
import { StyledModal } from "../shared/modal.styled";
import ContentWrapper from "../components/ContentWrapper";
import TableWrapper from "../components/TableWrapper";
import ListEntities from "../components/ListEntities";
import { typeStatus } from "../helpers/typeStatus";
import moment from "moment";

const StyledInput = styled(Input)`
  width: 100%;
  margin: 20px 0 25px;
  height: 44px;
  border-radius: 50px !important;
  padding: 4px 20px;
`;

const FooterControl = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0 15px;
  grid-template-areas: ". .";
`;

const ButtonIconWithText = styled(Button)`
  font-size: 14px;
  font-weight: normal;
  display: flex;
`;

const Span = styled(ColoredSpan)`
  cursor: pointer;
`;

const Vacancies: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const vacancies = useSelector((state: RootState) => state.vacancies);
  const [isHidden, setIsHidden] = useState(false);
  const [changeableName, setChangeableName] = useState<string | undefined>();
  const [activeTab, setActiveTab] = useState<"task" | "instrument">("task");
  const [selectedVacancy, setSelectedVacancy] = useState<string | undefined>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditingVisible, setIsModalEditingVisible] = useState(false);

  // Получаем выбранную вакансию
  const vacancy = vacancies.find((v) => v.id === selectedVacancy);
  // Считаем количество скрытых вакансий
  const hiddenCount = vacancies.filter((v) => v.is_archived).length;

  // Получаем список вакансий
  useEffect(() => {
    dispatch(fetchVacancies());
  }, [dispatch]);

  // Изменяем название вакансии
  const handleChangeName = async () => {
    if (!vacancy) return;
    try {
      const updatedVacancy = await VacancyAPI.update(vacancy.id, {
        name: changeableName,
      });
      dispatch(updateVacancy(updatedVacancy));
      message.success(`Название вакансии успешно изменено`);
      setIsModalEditingVisible(false);
    } catch (error) {}
  };

  // Показываем элементы связи
  const handleOpenModal = (tab: "task" | "instrument", vacancy: IVacancy) => {
    if (vacancy.status !== "done") return;
    setActiveTab(tab);
    setSelectedVacancy(vacancy.id);
    setIsModalVisible(true);
  };

  // Показываем окно изменения названия вакансии
  const handleOpenEditingModal = (vacancy: IVacancy) => {
    setSelectedVacancy(vacancy.id);
    setChangeableName(vacancy.name);
    setIsModalEditingVisible(true);
  };

  // Изменяем видимость вакансии/Скрываем/Отправляем в архив
  const handleChangeVisible = async (vacancy: IVacancy) => {
    try {
      const updatedVacancy = await VacancyAPI.update(vacancy.id, {
        is_archived: !vacancy.is_archived,
      });
      if (updatedVacancy) {
        dispatch(updateVacancy(updatedVacancy));
        message.success(`Вакансия отправлена в архив`);
      }
    } catch (error) {}
  };

  const columns: ColumnsType<IVacancy> = [
    {
      title: "Название вакансии",
      dataIndex: "name",
      render: (_, vacancy) => (
        <>
          <Link to={`/vacancy/${vacancy.id}`} style={{ color: "#000" }}>
            {vacancy.name}
          </Link>
          <Button
            type="link"
            onClick={() => handleOpenEditingModal(vacancy)}
            icon={<Edit primaryColor="#C1CCE7" size="medium" />}
          />
        </>
      ),
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      render: (created_at) => (
        <span>{moment(created_at).format("DD.MM.YYYY HH:mm")}</span>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      render: (_, vacancy) => <span>{typeStatus[vacancy.status]}</span>,
    },
    {
      title: "Задачи",
      dataIndex: "entities",
      key: "priority_tasks",
      render: (record: IEntity[], vacancy) => (
        <Span color="#21D996" onClick={() => handleOpenModal("task", vacancy)}>
          {record.filter((item) => item.type === "task").length}
        </Span>
      ),
    },
    {
      title: "Инструменты",
      dataIndex: "entities",
      key: "priority_tools",
      render: (record: IEntity[], vacancy) => (
        <Span
          color="#FF6B86"
          onClick={() => handleOpenModal("instrument", vacancy)}
        >
          {record.filter((item) => item.type === "instrument").length}
        </Span>
      ),
    },
    {
      title: "Резюме",
      dataIndex: "resume_count",
      render: (_, vacancy) =>
        vacancy.resume_count > 0 ? (
          <Link to={`/vacancy/${vacancy.id}/resumes`}>
            <ButtonIconWithText type="link" icon={<Folder size={20} />}>
              x{vacancy.resume_count}
            </ButtonIconWithText>
          </Link>
        ) : (
          <Link to={`/resume/${vacancy.id}`}>
            <Button type="link">
              <MoreSquare size={20} />
            </Button>
          </Link>
        ),
    },
    // {
    //   title: "Аудио",
    //   dataIndex: "interview_status",
    //   render: (status: InterviewSettingsStatus, vacancy: IVacancy) => {
    //     return ["running", "ended"].includes(status) ? (
    //       <Button
    //         type="link"
    //         disabled={!!vacancy.answers_count}
    //         icon={<Voice size={20} />}
    //         onClick={() => history.push(`/vacancy/${vacancy.id}/report`)}
    //       />
    //     ) : (
    //       <Button
    //         type="link"
    //         icon={<MoreSquare size={20} />}
    //         disabled={vacancy.resume_count === 0}
    //         onClick={() => history.push(`/audio/${vacancy.id}`)}
    //       />
    //     );
    //   },
    // },
    {
      title: () => (
        <Button
          type="text"
          onClick={() => setIsHidden(!isHidden)}
          disabled={hiddenCount === 0}
          title={isHidden ? "Показать активные" : "Показать скрытые"}
          icon={
            isHidden ? (
              <Show size={20} primaryColor="#130F26" set="bold" />
            ) : (
              <Hide size={20} set="bulk" primaryColor="#130F26" />
            )
          }
        />
      ),
      dataIndex: "is_archived",
      render: (_, vaccancy) => {
        return (
          <Button
            type="link"
            onClick={() => handleChangeVisible(vaccancy)}
            icon={
              vaccancy.is_archived ? (
                <span title="Сделать активным">
                  <Show size={20} />
                </span>
              ) : (
                <span title="Сделать неактивным">
                  <Hide size={20} />
                </span>
              )
            }
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "link",
      render: (_, vaccancy) => {
        return vaccancy.link ? (
          <a href={vaccancy.link}>
            <PaperDownload size={24} />
          </a>
        ) : null;
      },
    },
  ];

  const renderTabs = (props: any, DefaultTabBar: React.ComponentType) => {
    return (
      <DefaultTabBar {...props} className={`${activeTab}-ink-bar`}>
        {(node: any) => {
          const className = `${node.props.className} tab-${node.key}`;
          return React.cloneElement(node, { className });
        }}
      </DefaultTabBar>
    );
  };

  const filterVacancy = (vacancy: IVacancy): Boolean => {
    // Если разрешено показывать скрытые, то отображаем все
    if (isHidden) {
      return true;
    } else {
      // Отображаем только те, что is_archived === false
      return vacancy.is_archived === false;
    }
  };

  const sortVacancy = (a: IVacancy, b: IVacancy) => {
    return new Date(b.created_at) > new Date(a.created_at) ? 1 : -1;
  };

  const filteredData = vacancies.filter(filterVacancy).sort(sortVacancy);

  return (
    <ContentWrapper
      className="vacancies"
      title="Вакансии"
      icon={<Work size={20} primaryColor="#98A4BC" stroke="bold" />}
      onBack={() => null}
      footerContent={
        <LegendMessage>
          <p>
            Вакансии со значком{" "}
            <Folder
              size={16}
              primaryColor="#5061DC"
              stroke="bold"
              style={{ verticalAlign: "middle" }}
            />{" "}
            имеют обработанные резюме
            {/* и автоматически сформированные вопросы для */}
            {/* аудиоинтервью. */}
          </p>
          {/* <p>
            Вакансии со значком{" "}
            <Voice
              size={16}
              primaryColor="#5061DC"
              stroke="bold"
              style={{ verticalAlign: "middle" }}
            />{" "}
            содержат запись аудиоинтервью и отчет.
          </p> */}
        </LegendMessage>
      }
    >
      <TableWrapper
        rowClassName={(record) => record.is_archived && "transparency"}
        dataSource={filteredData}
        columns={columns}
        gridTemplateColumns="1fr 150px 120px 100px 150px 100px 60px 60px 60px"
      />
      <StyledModal
        visible={isModalVisible}
        title={null}
        footer={null}
        onCancel={() => {
          setSelectedVacancy(undefined);
          setIsModalVisible(false);
        }}
      >
        <Tabs
          activeKey={activeTab}
          onTabClick={(key: any) => setActiveTab(key)}
          renderTabBar={renderTabs}
        >
          <Tabs.TabPane tab="Задачи" key="task">
            <ListEntities type={activeTab} entities={vacancy?.entities || []} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Инструменты" key="instrument">
            <ListEntities type={activeTab} entities={vacancy?.entities || []} />
          </Tabs.TabPane>
        </Tabs>
      </StyledModal>
      <StyledModal
        visible={isModalEditingVisible}
        title={null}
        footer={null}
        onCancel={() => setIsModalEditingVisible(false)}
      >
        <StyledInput
          value={changeableName}
          onChange={(e) => setChangeableName(e.target.value)}
          key={vacancy?.id}
        />
        <FooterControl>
          <Button
            size="large"
            type="default"
            aria-label="Close"
            onClick={() => setIsModalEditingVisible(false)}
          >
            Отменить
          </Button>
          <Button size="large" type="primary" onClick={handleChangeName}>
            Сохранить
          </Button>
        </FooterControl>
      </StyledModal>
    </ContentWrapper>
  );
};

export default Vacancies;
