import { useState } from "react";
import Input from "antd/lib/input";

import { typeColor } from "../../helpers/typeColor";
import { ScrolledBlock, WhiteAroundBlock } from "../../shared/block.styled";
import { IEntity } from "../../store/entities.reducer";
import Tabs from "../tabs/Tabs";
import { TransferButton } from "./transfer.styled";
import TransferSourceItem from "./TransferSourceItem";

type TransferSourceProps = {
  dataSource: IEntity[];
  onAppend?: (entites: IEntity[]) => void;
  rowId: keyof IEntity;
};

const TransferSource: React.FC<TransferSourceProps> = ({ dataSource, onAppend, rowId }) => {
  const [activeTab, setActiveTab] = useState("task");
  const [search, setSearch] = useState("");
  const handleChangeTab = (key: string) => setActiveTab(key);

  const filteredData = dataSource
    .filter((item) => item.type === activeTab)
    .filter((item) => (search ? item.name.toLowerCase().includes(search.toLowerCase()) : true));

  return (
    <WhiteAroundBlock>
      <Tabs
        activeTab={activeTab}
        onChange={handleChangeTab}
        extra={
          <Input placeholder="Фильтр" value={search} onChange={(e) => setSearch(e.target.value)} />
        }
      >
        <Tabs.TabPane title="Задачи" color={typeColor.task} key="task">
          <ScrolledBlock height={400}>
            {filteredData.map((item) => (
              <TransferSourceItem
                key={item[rowId]?.toString()}
                color={typeColor[item.type]}
                {...item}
              />
            ))}
          </ScrolledBlock>
        </Tabs.TabPane>
        <Tabs.TabPane title="Инструменты" color={typeColor.instrument} key="instrument">
          <ScrolledBlock height={400}>
            {filteredData.map((item) => (
              <TransferSourceItem
                key={item[rowId]?.toString()}
                color={typeColor[item.type]}
                {...item}
              />
            ))}
          </ScrolledBlock>
        </Tabs.TabPane>
      </Tabs>
      {onAppend && (
        <TransferButton onClick={() => onAppend(filteredData)}>Добавить все</TransferButton>
      )}
    </WhiteAroundBlock>
  );
};

export default TransferSource;
