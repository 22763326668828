import axios from "axios";
import { IEntity } from "../store/entities.reducer";
import { IPosition } from "../store/positions.reducer";

const fetch = async (): Promise<IPosition[]> => {
  try {
    const response = await axios(`/positions`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const fetchEntitiesById = async (position_id: string): Promise<IEntity[]> => {
  try {
    const response = await axios(`/position_entities/${position_id}`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const PositionsAPI = { fetch, fetchEntitiesById };

export default PositionsAPI;
