import { InterviewContent, InterviewWrapper, Smile } from "./interview.styled";

const InterviewSuccess = () => {
  return (
    <InterviewWrapper>
      <InterviewContent align="center">
        <Smile>👍</Smile>
        <h3>Благодарим вас за ответы!</h3>
        <p>Запись интервью отправлена работодателю. Теперь эту вкладку можно закрыть.</p>
      </InterviewContent>
    </InterviewWrapper>
  );
};

export default InterviewSuccess;
