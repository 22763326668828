import Button from "antd/lib/button";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Row from "antd/lib/row";
import Dragger from "antd/lib/upload/Dragger";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import { Folder, PaperDownload } from "react-iconly";

import { RootState } from "store";
import { fetchVacancies } from "store/vacancies.reducer";
import { IFile } from "store/files.types";
import { setResumes } from "store/resumes.reducer";
import { useResumeStatus } from "hooks/resumes.hook";
import FilesAPI from "api/files.api";
import ResumesAPI, { ResumeFilter } from "api/resumes.api";
import ContentWrapper from "components/ContentWrapper";
import Checkbox from "components/Checkbox";
import Processing from "components/Processing";
import FilterResume from "components/filter/FilterResume";
import { HighLight } from "shared/text.styled";
import { WhiteAroundBlock } from "shared/block.styled";

const Uploader = styled(Dragger)`
  padding: 30px 0;
  background: #ffffff;
  border: 1px dashed #98a4bc;
  border-radius: 16px;
  margin-bottom: 20px;
`;

const FooterControl = styled.div`
  text-align: right;
`;

type RouteParams = {
  vacancy_id: string;
};

const ResumeMapping: React.FC<RouteComponentProps<RouteParams>> = ({ match, history }) => {
  const { vacancy_id } = match.params;
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  const [files, setFiles] = useState<IFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const vacancies = useSelector((state: RootState) => state.vacancies);
  const vacancy = vacancies.find((v) => v.id === vacancy_id);
  const [sources, setSources] = useState<{ [key: string]: boolean }>({
    superjob: false,
    leaderid: false,
    trudvsem: false,
  });

  const [filters, setFilters] = useState<ResumeFilter>({
    countries: [],
    cities: [],
    age_from: 18,
    age_to: 60,
  });

  const { isAvailable } = useResumeStatus(vacancy_id);

  useEffect(() => {
    vacancies.length === 0 && dispatch(fetchVacancies());
  }, [dispatch, vacancies.length]);

  /**
   * Добавляем в стейт информацию о загруженных файлах
   * @param info информация о загруженном файле
   */
  const handleChangeFiles = (info: UploadChangeParam<UploadFile<IFile>>) => {
    const { status } = info.file;
    console.log("info :>> ", info);
    if (status === "done") {
      const files = info.fileList.map((file) => file.response);
      setFiles(files);
      message.success(`${info.file.name} загружен!`);
    } else if (status === "error") {
      message.error(`${info.file.name} не загружен!`);
    }
  };

  /**
   * Удаляем файл
   * @param file Информация о файле
   */
  const handleRemoveFile = async (file: UploadFile<IFile>) => {
    const id = file.response?.id;
    if (id) {
      // Удаляем с сервера
      const isDeleted = await FilesAPI.remove(id);
      if (isDeleted) {
        // Удаляем из стейта
        setFiles(files.filter((f) => f.id !== id));
      }
    }
  };

  const actionProps = {
    action: `${process.env.REACT_APP_BACKEND}/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleChangeParsingSource = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSources({ ...sources, [e.target.name]: e.target.checked });
  };

  const handleChangeAgeRange = (age_from: number, age_to: number) =>
    setFilters({ ...filters, age_from, age_to });

  /**
   * Добавляем новое резюме
   */
  const handleAddResumes = async () => {
    try {
      // Собираем айдишники загруженных файлов
      const ids_files = files.map((f) => f.id);
      // Собираем ключи выбранных источников парсинга
      const keys_sources = Object.keys(sources).filter((s) => sources[s]);
      // Отправляем данные на сервер
      setIsLoading(true);
      const resumes = await ResumesAPI.add(vacancy_id, ids_files, keys_sources, filters);
      if (resumes) {
        // Актуализируем список резюме
        dispatch(setResumes(resumes));
        // Переходим к маппингу резюме
        history.push(`/vacancy/${vacancy_id}/resumes`);
      }
    } catch (error) {
      message.error("Не удалось добавить новые резюме. Попробуйте еще раз.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeFilter = (countries: number[], cities: number[]) => {
    setFilters({ ...filters, countries, cities });
  };

  if (!isAvailable || vacancy?.status !== "done") {
    return <Processing />;
  }

  // Проверяем выбран ли хотя бы один источник
  const isSourceChecked = Object.values(sources).some((check) => check);

  return (
    <ContentWrapper
      title={
        <>
          Загрузка резюме для вакансии: <HighLight color="#5B6673">{vacancy?.name}</HighLight>
        </>
      }
      icon={<Folder set="light" primaryColor="#98A4BC" size={20} />}
      onBack={() => null}
      footerContent={
        <FooterControl>
          <Button
            size="large"
            type="primary"
            loading={isLoading}
            disabled={!vacancy || (!isSourceChecked && files.length === 0)}
            onClick={handleAddResumes}
          >
            Далее
          </Button>
        </FooterControl>
      }
    >
      <WhiteAroundBlock>
        <Row>
          <Col span={24} style={{ paddingBottom: 50 }}>
            <Uploader
              showUploadList={false}
              data={(file) => ({ name: file.name })}
              accept=".txt,.doc,.docx,.pdf"
              multiple
              onChange={handleChangeFiles}
              onRemove={handleRemoveFile}
              {...actionProps}
            >
              <p className="ant-upload-text">
                <PaperDownload set="light" primaryColor="#5061DC" size="medium" />
                <span>Загрузите все полученные резюме</span>
              </p>
              <p className="ant-upload-hint">Форматы: .TXT, .DOC, .DOCX, .PDF</p>
            </Uploader>
          </Col>
          <Col span={24}>
            <p className="ant-upload-count">Загружено файлов: {files.length}</p>
          </Col>
          <Col span={24}>
            <Checkbox
              name="superjob"
              checked={sources.superjob}
              onChange={handleChangeParsingSource}
            >
              SuperJob
            </Checkbox>
            {/* <Checkbox
              name="leaderid"
              checked={sources.leaderid}
              onChange={handleChangeParsingSource}
            >
              LeaderID
            </Checkbox>
            <Checkbox
              name="trudvsem"
              checked={sources.trudvsem}
              onChange={handleChangeParsingSource}
            >
              TrudVsem
            </Checkbox> */}
          </Col>
          {isSourceChecked && (
            <Col span={24}>
              <FilterResume
                countries={filters.countries}
                cities={filters.cities}
                onChange={handleChangeFilter}
                onChangeAgeRange={handleChangeAgeRange}
                ageRange={[filters.age_from, filters.age_to]}
              />
            </Col>
          )}
        </Row>
      </WhiteAroundBlock>
    </ContentWrapper>
  );
};

export default ResumeMapping;
