import Col from "antd/lib/col";
import Layout from "antd/lib/layout";
import Row from "antd/lib/row";

const Footer = () => {
  return (
    <Layout.Footer>
      <Row>
        <Col span={11} offset={1}>
          <p>&copy; Университет НТИ &laquo;20.35&raquo;, 2021</p>
        </Col>
        <Col span={11}>
          <p>О программе CVlla</p>
        </Col>
      </Row>
    </Layout.Footer>
  );
};

export default Footer;
