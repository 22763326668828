import { LinkOutlined, PaperClipOutlined } from "@ant-design/icons";
import Button from "antd/lib/button";
import Popover from "antd/lib/popover";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useMemo, useState } from "react";
import { Danger, PaperDownload, Chart } from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { RootState } from "../store";
import { fetchVacancies } from "../store/vacancies.reducer";
import { fetchResumes, IResume, updateResume } from "../store/resumes.reducer";
import ResumesAPI from "../api/resumes.api";
import FilesAPI from "../api/files.api";
import { useResumeStatus } from "../hooks/resumes.hook";
import { typeColor } from "../helpers/typeColor";
import { StyledModal } from "../shared/modal.styled";
import { ColoredSpan, HighLight, LegendMessage } from "../shared/text.styled";

import Checkbox from "../components/Checkbox";
import ContentWrapper from "../components/ContentWrapper";
import ListEntities from "../components/ListEntities";
import Processing from "../components/Processing";
import EmailNotDetected from "../components/resume/EmailNotDetected";
import TableWrapper from "../components/TableWrapper";
import Tabs from "../components/tabs/Tabs";

const Span = styled(ColoredSpan)`
  cursor: pointer;
  font-weight: 500;
`;

const Name = styled(Button)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface IResumeColumns extends Partial<IResume> {
  id: string;
  tasks_count: number;
  tools_count: number;
  vacancy_tasks_count: number;
  vacancy_tools_count: number;
  rating: number;
  max_rating: number;
}

type RouteParams = {
  vacancy_id: string;
};

const Resumes: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { vacancy_id } = match.params;
  const dispatch = useDispatch();
  const { resumes, vacancies } = useSelector((state: RootState) => state);
  const [selectedResume, setSelectedResume] = useState<string | undefined>();
  const [activeTab, setActiveTab] = useState("task");
  const { isAvailable } = useResumeStatus(vacancy_id);
  const vacancy = vacancies.find((v) => v.id === vacancy_id);

  const downloadLink = `${process.env.REACT_APP_BACKEND}/download/`;

  /**
   * Подгружаем вакансии если их нет
   */
  useEffect(() => {
    vacancies.length === 0 && dispatch(fetchVacancies());
  }, [dispatch, vacancies.length]);

  const vacancy_entitites = useMemo(() => {
    let vacancy_tasks_count = 0;
    let vacancy_tools_count = 0;
    const entities = vacancy?.entities || [];
    for (const entity of entities) {
      if (entity.type === "task") {
        vacancy_tasks_count++;
      }
      if (entity.type === "instrument") {
        vacancy_tools_count++;
      }
    }
    return {
      vacancy_tasks_count,
      vacancy_tools_count,
      max_rating: vacancy_tasks_count + vacancy_tools_count,
    };
  }, [vacancy]);

  const data = useMemo(
    () =>
      resumes.reduce<IResumeColumns[]>((acc, resume) => {
        // Задаем дефолтные значения для элементов связей
        let tasks_count = 0;
        let tools_count = 0;
        // Считаем элементы по типу и приоритетности за один цикл
        for (const entity of resume.entities) {
          if (entity.type === "task") {
            tasks_count++;
          }
          if (entity.type === "instrument") {
            tools_count++;
          }
        }

        const rating = tasks_count + tools_count;

        return acc.concat({
          id: resume.id,
          name: resume.name,
          email: resume.email,
          vacancy_id: resume.vacancy_id,
          is_selected: resume.is_selected,
          tasks_count,
          tools_count,
          file_id: resume.file_id,
          interview_link_id: resume.interview_link_id,
          source_link: resume.source_link,
          rating,
          ...vacancy_entitites,
        });
      }, []),
    [resumes, vacancy_entitites]
  );

  const handleChangeTab = (key: string) => setActiveTab(key);

  const handleOpenModal = (tab: "task" | "instrument", resume_id: string) => {
    setActiveTab(tab);
    setSelectedResume(resume_id);
  };

  /**
   * Выбираем резюме для приглашени в участии в аудиоинтервью
   */
  const handleSendInvite = async (
    e: React.ChangeEvent<HTMLInputElement>,
    resume_id: string
  ) => {
    const is_selected = e.target.checked;
    try {
      const updatedResume = await ResumesAPI.update(vacancy_id, resume_id, {
        is_selected,
      });
      if (updatedResume) {
        dispatch(updateResume(updatedResume));
      }
    } catch (error) {}
  };

  useEffect(() => {
    isAvailable && dispatch(fetchResumes(vacancy_id));
  }, [dispatch, vacancy_id, isAvailable]);

  const handlePreview = async (file_id?: string) => {
    if (!file_id) return;
    try {
      const file = await FilesAPI.get(file_id);
      if (file) {
        let blob = new Blob([file.data], {
          type: file.headers["content-type"],
        });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      }
    } catch (error) {}
  };

  const columns: ColumnsType<IResumeColumns> = [
    {
      title: "",
      dataIndex: "is_selected",
      render: (_, resume) => (
        <Checkbox
          name={resume.id}
          checked={resume.is_selected}
          onChange={(e) => handleSendInvite(e, resume.id)}
          color="#5B6673"
        />
      ),
    },
    {
      title: "Резюме",
      dataIndex: "name",
      render: (name, resume) =>
        !resume.email ? (
          <>
            <Name
              type="text"
              title="Предпросмотр"
              onClick={() => handlePreview(resume.file_id)}
            >
              {name}
            </Name>
            <Popover
              placement="right"
              content={<EmailNotDetected resume={resume} />}
              title={null}
              trigger="click"
            >
              <Button
                type="link"
                size="small"
                icon={
                  <Danger set="light" size="small" primaryColor="#FFA841" />
                }
              />
            </Popover>
          </>
        ) : (
          <Button
            type="text"
            onClick={() => handlePreview(resume.file_id)}
            title="Предпросмотр"
          >
            {name}
          </Button>
        ),
    },
    {
      title: "Рейтинг",
      dataIndex: "rating",
      sorter: (a, b) => a.rating - b.rating,
      render: (rating, resume) => {
        return (
          <Span color="#200E32">
            {rating}/{resume.max_rating}
          </Span>
        );
      },
    },
    {
      title: "Задачи",
      dataIndex: "tasks_count",
      sorter: (a, b) => a.tasks_count - b.tasks_count,
      render: (tasks_count: number, resume) => (
        <Span
          color="#21D996"
          onClick={() => handleOpenModal("task", resume.id)}
        >
          {tasks_count}/{resume.vacancy_tasks_count}
        </Span>
      ),
    },
    {
      title: "Инструменты",
      dataIndex: "tools_count",
      sorter: (a, b) => a.tools_count - b.tools_count,
      render: (tools_count: number, resume) => (
        <Span
          color="#FF6B86"
          onClick={() => handleOpenModal("instrument", resume.id)}
        >
          {tools_count}/{resume.vacancy_tools_count}
        </Span>
      ),
    },
    // {
    //   title: "Аудио-интервью",
    //   dataIndex: "interview_link_id",
    //   render: (interview_link_id) => {
    //     return interview_link_id ? (
    //       <Link to={`/interview/${interview_link_id}`}>
    //         <Button type="link" icon={<LinkOutlined />} />
    //       </Link>
    //     ) : null;
    //   },
    // },
    {
      title: "Источник",
      dataIndex: "source_link",
      render: (source_link) => {
        return source_link ? (
          <a href={source_link} target="_blank" rel="noreferrer">
            <Button type="link" icon={<PaperClipOutlined />} />
          </a>
        ) : null;
      },
    },
    {
      title: "",
      dataIndex: "file_id",
      render: (file_id: string) => (
        <a href={downloadLink + file_id} target="_blank" rel="noreferrer">
          <Button
            type="link"
            icon={<PaperDownload set="light" primaryColor="#5061DC" />}
          />
        </a>
      ),
    },
  ];

  const entities =
    resumes.find((item) => item.id === selectedResume)?.entities || [];

  if (!isAvailable) {
    return <Processing />;
  }

  return (
    <ContentWrapper
      title={
        <>
          Рейтинг резюме для вакансии:{" "}
          <HighLight color="#5061DC">{vacancy?.name}</HighLight>
        </>
      }
      icon={<Chart set="light" primaryColor="#98A4BC" />}
      onBack={() => null}
      extra={<Link to="/vacancies">К списку вакансий</Link>}
      // footerContent={
      //   <LegendMessage>
      //     <p>
      //       Кандидаты, чьи резюме отмечены в списке, будут приглашены на
      //       аудиоинтервью.
      //     </p>
      //   </LegendMessage>
      // }
    >
      <TableWrapper
        dataSource={data}
        columns={columns}
        gridTemplateColumns="40px minmax(300px,1fr) 150px 150px 150px 150px 100px 50px"
      />
      <StyledModal
        visible={!!selectedResume}
        title={null}
        footer={null}
        onCancel={() => setSelectedResume(undefined)}
      >
        <Tabs activeTab={activeTab} onChange={handleChangeTab}>
          <Tabs.TabPane title="Задачи" color={typeColor.task} key="task">
            <ListEntities type="task" entities={entities} />
          </Tabs.TabPane>
          <Tabs.TabPane
            title="Инструменты"
            color={typeColor.instrument}
            key="instrument"
          >
            <ListEntities type="instrument" entities={entities} />
          </Tabs.TabPane>
        </Tabs>
      </StyledModal>
    </ContentWrapper>
  );
};

export default Resumes;
