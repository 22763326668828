import { typeColor } from "../helpers/typeColor";
import { ScrolledBlock } from "../shared/block.styled";
import { EntityType, IEntity } from "../store/entities.reducer";
import EntityTag from "./EntityTag";

type ListEntitiesProps = {
  type: EntityType;
  entities: IEntity[];
};

const ListEntities: React.FC<ListEntitiesProps> = ({ entities, type }) => {
  return (
    <ScrolledBlock height={400}>
      {entities
        .filter((item) => item.type === type)
        .map((task) => (
          <EntityTag key={task.entity_id} {...task} color={typeColor[type]} />
        ))}
    </ScrolledBlock>
  );
};

export default ListEntities;
