import styled from "styled-components";
import Select from "antd/lib/select";
import Slider from "antd/lib/slider";

export const FilterResumeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 200px 200px;
  gap: 20px;
  margin: 20px 0 5px;
`;

export const FilterResumeSelect = styled(Select)`
  min-width: 200px;
`;

export const StyledSlider = styled(Slider)`
  min-width: 200px;
`;
