import axios from "axios";
import { IInterviewSettings } from "../store/interview.types";
import { IQuestion } from "../store/questions.reducer";
import { IVacancy } from "../store/vacancies.reducer";

type Answer = {
  question_id: string;
  file_id?: string;
};

const fetch = async (vacancy_id: string) => {
  try {
    const response = await axios.get<IInterviewSettings>(
      `/vacancies/${vacancy_id}/interview_settings`
    );
    return response.data;
  } catch (error) {
    throw new Error("Не удалось получить настройки. Повторите попытку.");
  }
};

const update = async (vacancy_id: string, options: Partial<IInterviewSettings>) => {
  try {
    const response = await axios.patch(`/vacancies/${vacancy_id}/interview_settings`, options);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось обновить настройки. Повторите попытку.");
  }
};

const getVacancy = async (interview_link_id: string) => {
  try {
    const response = await axios.get<{ vacancy: IVacancy }>(
      `/interview_links/${interview_link_id}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Не удалось получить настройки. Повторите попытку.");
  }
};

const getQuestions = async (interview_link_id: string) => {
  try {
    const response = await axios.get<IQuestion[]>(`/interview/${interview_link_id}/questions`);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось получить настройки. Повторите попытку.");
  }
};

const saveAnswers = async (interview_link_id: string, answers: Answer[]) => {
  try {
    const response = await axios.post(`/interview/${interview_link_id}/answers`, { answers });
    return response.data;
  } catch (error) {
    throw new Error("Не удалось отправить ответы. Повторите попытку.");
  }
};

export const InterviewAPI = { getVacancy, getQuestions, saveAnswers };

export const InterviewSettingsAPI = { fetch, update };
