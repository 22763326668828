import Button from "antd/lib/button";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Row from "antd/lib/row";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { PaperDownload, Scan, Search } from "react-iconly";

import ContentWrapper from "../components/ContentWrapper";
import { WhiteAroundBlock } from "../shared/block.styled";
import { RootState } from "../store";
import { fetchVacancies } from "../store/vacancies.reducer";
import Checkbox from "../components/Checkbox";
import FilesAPI from "../api/files.api";
import { IFile } from "../store/files.types";
import ResumesAPI, { ResumeFilter } from "../api/resumes.api";
import { setResumes } from "../store/resumes.reducer";
import { StyledSelect, Uploader } from "../shared/forms.styled";
import FilterResume from "components/filter/FilterResume";

const FooterControl = styled.div`
  text-align: right;
`;

const Resume: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  const [files, setFiles] = useState<IFile[]>([]);
  const vacancies = useSelector((state: RootState) => state.vacancies);
  const [vacancyId, setVacancyId] = useState<string | undefined>();
  const [sources, setSources] = useState<{ [key: string]: boolean }>({
    superjob: false,
    leaderid: false,
    trudvsem: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState<ResumeFilter>({
    countries: [],
    cities: [],
    age_from: 18,
    age_to: 60,
  });

  const handleChangeAgeRange = (age_from: number, age_to: number) =>
    setFilters({ ...filters, age_from, age_to });

  // Подгружаем вакансии если их нет
  useEffect(() => {
    vacancies.length === 0 && dispatch(fetchVacancies());
  }, [dispatch, vacancies.length]);

  /**
   * Загружаем файлы резюме
   * @param info Информация о загруженном файле
   */
  const handleChangeFiles = (info: UploadChangeParam<UploadFile<IFile>>) => {
    const { status } = info.file;
    console.log("info :>> ", info);
    if (status === "done") {
      const files = info.fileList.map((file) => file.response);
      setFiles(files);
      message.success(`${info.file.name} загружен!`);
    } else if (status === "error") {
      message.error(`${info.file.name} не загружен!`);
    }
  };

  /**
   * Удаляем файл
   * @param file удаляемый файл
   */
  const handleRemoveFile = async (file: UploadFile<IFile>) => {
    const id = file.response?.id;
    if (id) {
      // Удаляем с сервера
      const isDeleted = await FilesAPI.remove(id);
      if (isDeleted) {
        // Удаляем из стейта
        setFiles(files.filter((f) => f.id !== id));
      }
    }
  };

  /**
   * Чекаем источники парсинга
   * @param e событие чекбокса
   */
  const handleChangeParsingSource = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSources({ ...sources, [e.target.name]: e.target.checked });
  };

  /**
   * Добавляем новое резюме
   */
  const handleAddResumes = async () => {
    if (!vacancyId) return;
    try {
      // Собираем айдишники загруженных файлов
      const ids_files = files.map((f) => f.id);
      // Собираем ключи выбранных источников парсинга
      const keys_sources = Object.keys(sources).filter((s) => sources[s]);
      // Отправляем данные на сервер
      setIsLoading(true);
      const resumes = await ResumesAPI.add(vacancyId, ids_files, keys_sources, filters);
      if (resumes) {
        // Актуализируем список резюме
        dispatch(setResumes(resumes));
        // Переходим к списку резюме
        history.push(`/vacancy/${vacancyId}/resumes`);
      }
    } catch (error) {
      message.error("Не удалось добавить новые резюме. Попробуйте еще раз.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeFilter = (countries: number[], cities: number[]) => {
    setFilters({ ...filters, countries, cities });
  };

  // Настройки формирования запроса загрузки файла на сервер
  const actionProps = {
    action: `${process.env.REACT_APP_BACKEND}/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Список вакансий
  const options = vacancies.map((item) => ({
    label: item.name,
    value: item.id,
    disabled: !item.is_available || item.resume_count > 0,
  }));

  // Проверяем выбран ли хотя бы один источник
  const isSourceChecked = Object.values(sources).some((check) => check);

  return (
    <ContentWrapper
      title="Маппинг резюме"
      icon={<Scan set="light" primaryColor="#98A4BC" size={20} />}
      onBack={() => null}
      footerContent={
        <FooterControl>
          <Button
            size="large"
            type="primary"
            loading={isLoading}
            disabled={!vacancyId || (!isSourceChecked && files.length === 0)}
            onClick={handleAddResumes}
          >
            Далее
          </Button>
        </FooterControl>
      }
    >
      <WhiteAroundBlock>
        <Row>
          <Col span={12} offset={6}>
            <StyledSelect
              suffixIcon={<Search set="light" primaryColor="#5061DC" size={20} />}
              allowClear
              showSearch
              size="large"
              options={options}
              optionFilterProp="label"
              onChange={(value: unknown) => setVacancyId(value as string)}
              placeholder="Введите название вакансии..."
            />
          </Col>
          <Col span={24} style={{ paddingBottom: 50 }}>
            <Uploader
              data={(file) => ({ name: file.name })}
              accept=".txt,.doc,.docx,.pdf"
              showUploadList={false}
              multiple
              onChange={handleChangeFiles}
              onRemove={handleRemoveFile}
              {...actionProps}
            >
              <p className="ant-upload-text">
                <PaperDownload set="light" primaryColor="#5061DC" size="medium" />
                <span>Загрузите все полученные резюме</span>
              </p>
              <p className="ant-upload-hint">Форматы: .TXT, .DOC, .DOCX, .PDF</p>
            </Uploader>
          </Col>
          <Col span={24}>
            <p className="ant-upload-count">Загружено файлов: {files.length}</p>
          </Col>
          <Col span={24}>
            <Checkbox
              name="superjob"
              checked={sources.superjob}
              onChange={handleChangeParsingSource}
            >
              SuperJob
            </Checkbox>
            {/* <Checkbox
              name="leaderid"
              checked={sources.leaderid}
              onChange={handleChangeParsingSource}
            >
              LeaderID
            </Checkbox>
            <Checkbox
              name="trudvsem"
              checked={sources.trudvsem}
              onChange={handleChangeParsingSource}
            >
              TrudVsem
            </Checkbox> */}
          </Col>
          {isSourceChecked && (
            <Col span={24}>
              <FilterResume
                countries={filters.countries}
                cities={filters.cities}
                onChange={handleChangeFilter}
                onChangeAgeRange={handleChangeAgeRange}
                ageRange={[filters.age_from, filters.age_to]}
              />
            </Col>
          )}
        </Row>
      </WhiteAroundBlock>
    </ContentWrapper>
  );
};

export default Resume;
