import { Empty, Table } from "antd";
import styled from "styled-components";
import { TableProps } from "antd/lib/table/Table";

interface TableWrapperProps<RecordType = any> extends TableProps<RecordType> {
  gridTemplateColumns?: string;
}

const StyledTableWrapper = styled(Table)<TableWrapperProps>`
  .ant-table-thead tr {
    display: grid;
    grid-template-columns: ${({ columns, gridTemplateColumns }) =>
      gridTemplateColumns ? gridTemplateColumns : `repeat(${columns?.length}, 1fr)`};
    .ant-table-cell {
      background-color: transparent;
      border-bottom: 0;
    }
  }
  .ant-table-tbody tr {
    display: grid;
    grid-template-columns: ${({ columns, gridTemplateColumns }) =>
      gridTemplateColumns ? gridTemplateColumns : `repeat(${columns?.length}, 1fr)`};
    td:last-child {
      justify-content: flex-end;
    }
  }

  .ant-table-tbody tr.transparency {
    opacity: 0.6;
  }

  .ant-btn-text {
    font-weight: normal;
    font-size: 15px;
    &:hover {
      background: none;
    }
  }
`;

const TableWrapper: React.FC<TableWrapperProps> = ({
  gridTemplateColumns,
  dataSource,
  ...props
}) => {
  if (dataSource?.length === 0) {
    return <Empty description="Нет данных" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <StyledTableWrapper
      rowKey="id"
      dataSource={dataSource}
      gridTemplateColumns={gridTemplateColumns}
      pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
      {...props}
    />
  );
};

export default TableWrapper;
