import { useDrop } from "react-dnd";
import styled from "styled-components";
import { typeColor } from "../../helpers/typeColor";
import { ScrolledBlock, WhiteAroundBlock } from "../../shared/block.styled";
import { InfoMessage } from "../../shared/text.styled";
import { IEntity } from "../../store/entities.reducer";
import EntityTag from "../EntityTag";
import { TransferButton } from "./transfer.styled";

type TransferTargetProps = {
  values: IEntity[];
  onDrop: (item: IEntity) => void;
  onRemove: (id: string) => void;
  onReset?: () => void;
  onDoubleClick?: (id: string) => void;
  rowId: keyof IEntity;
};

const DropBlock = styled(ScrolledBlock)<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? "#ccc" : "transparent")};
`;

const InfoBlock = styled(DropBlock)`
  display: flex;
  align-items: center;
  text-align: center;
`;

const TransferTarget: React.FC<TransferTargetProps> = ({
  values,
  onDrop,
  onReset,
  onRemove,
  onDoubleClick,
  rowId,
}) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ["task", "instrument"],
      drop: onDrop,
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  );

  const isActive = canDrop && isOver;

  if (values.length === 0) {
    return (
      <WhiteAroundBlock ref={drop}>
        <InfoBlock height={500} isActive={isActive}>
          <InfoMessage>
            Перетащите нужные элементы сюда и нажатием выделите те из них,
            которые являются приоритетными для данной позиции (не больше 3).
            {/* Вопросы по соответствующим им
            компетенциям будут заданы в ходе аудиоинтервью. */}
          </InfoMessage>
        </InfoBlock>
      </WhiteAroundBlock>
    );
  }

  return (
    <WhiteAroundBlock>
      <DropBlock height={455} isActive={isActive} ref={drop}>
        {values.map((item) => (
          <EntityTag
            color={typeColor[item.type]}
            key={item[rowId]?.toString()}
            {...item}
            onRemove={onRemove}
            onDoubleClick={onDoubleClick}
          />
        ))}
      </DropBlock>
      {onReset && (
        <TransferButton onClick={onReset}>Очистить все</TransferButton>
      )}
    </WhiteAroundBlock>
  );
};

export default TransferTarget;
