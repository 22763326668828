import { LeftOutlined } from "@ant-design/icons";
import { Col, PageHeader, Row } from "antd";
import React from "react";

type ContentWrapperProps = {
  title: React.ReactNode;
  icon: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  extra?: React.ReactNode;
  onBack?: () => void;
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
};

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  title,
  icon,
  extra,
  isLoading,
  onBack,
  className,
  headerContent,
  footerContent,
  children,
}) => {
  return (
    <Row className="content">
      <Col span={22} offset={1} className={`content__body ${className}`}>
        <PageHeader
          className="content__body-title"
          title={title}
          extra={extra}
          onBack={onBack}
          backIcon={icon ? icon : <LeftOutlined />}
          footer={headerContent}
        />
        {children}
      </Col>
      <Col span={22} offset={1} className={`content__footer ${className}`}>
        {footerContent}
      </Col>
    </Row>
  );
};

export default ContentWrapper;
