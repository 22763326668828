import { useEffect, useState } from "react";
import styled from "styled-components";

const Circle = styled.circle`
  transition: all 1s linear;
`;

const Preloader = () => {
  const [activeDot, setActiveDot] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (activeDot >= 0 && activeDot < 4) {
        setActiveDot(activeDot + 1);
      } else {
        setActiveDot(0);
      }
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [activeDot, setActiveDot]);

  return (
    <svg
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Circle cx="9" cy={activeDot === 0 ? 31 : 65} r="9" fill="#8564FF" />
      <Circle cx="37" cy={activeDot === 1 ? 31 : 65} r="9" fill="#21D996" />
      <Circle cx="65" cy={activeDot === 2 ? 31 : 65} r="9" fill="#FFA841" />
      <Circle cx="93" cy={activeDot === 3 ? 31 : 65} r="9" fill="#FF6B86" />
    </svg>
  );
};

export default Preloader;
