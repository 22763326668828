import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { Content } from "antd/lib/layout/layout";
import Layout from "antd/lib/layout";
import Spin from "antd/lib/spin";

import { RootState } from "./store";
import { useAuth } from "./hooks/auth.hook";

import ProtectedRoute from "./components/ProtectedRoute";
import Header from "./components/Header";
import Footer from "./components/Footer";

import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Vacancies from "./pages/Vacancies";
import Vacancy from "./pages/Vacancy";
import VacancyMapping from "./pages/VacancyMapping";
import ResumeMapping from "./pages/ResumeMapping";
import Resumes from "./pages/Resumes";
import Resume from "./pages/Resume";
import AudioInterview from "./pages/AudioInterview";
import AudioInterviewQuestions from "./pages/AudioInterviewQuestions";
import Interview from "./pages/guest/interview/Interview";
import InterviewProccess from "./pages/guest/interview/InterviewProccess";
import InterviewSuccess from "./pages/guest/interview/InterviewSuccess";
import Moderation from "./pages/analyst/Moderation";
import ModerationTask from "./pages/analyst/ModerationTask";
import Report from "./pages/Report";

import "./scss/index.scss";

function App() {
  const { isLoading } = useSelector((state: RootState) => state.app);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { isChecking } = useAuth();

  if (isChecking) return <Spin tip="Проверка авторизации" />;

  return (
    <Spin tip="Загрузка данных..." spinning={isLoading}>
      <Layout hasSider={false}>
        <Router>
          {isLoggedIn && <Header />}
          <Content>
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/interview/:interview_link_id" exact component={Interview} />
              <Route
                path="/interview/:interview_link_id/success"
                exact
                component={InterviewSuccess}
              />
              <Route
                path="/interview/:interview_link_id/:page"
                exact
                component={InterviewProccess}
              />
              <ProtectedRoute
                path="/moderation"
                exact
                component={Moderation}
                allowed={["analyst"]}
              />
              <ProtectedRoute
                path="/moderation/:id"
                exact
                component={ModerationTask}
                allowed={["analyst"]}
              />
              <ProtectedRoute path="/vacancies" exact component={Vacancies} allowed={["admin"]} />
              <ProtectedRoute
                path="/vacancy/:vacancy_id"
                exact
                component={VacancyMapping}
                allowed={["admin"]}
              />
              <ProtectedRoute path="/vacancy" exact component={Vacancy} allowed={["admin"]} />
              <ProtectedRoute
                path="/vacancy/:vacancy_id/resumes"
                exact
                component={Resumes}
                allowed={["admin"]}
              />
              <ProtectedRoute
                path="/resume/:vacancy_id"
                exact
                component={ResumeMapping}
                allowed={["admin"]}
              />
              <ProtectedRoute path="/resume" exact component={Resume} allowed={["admin"]} />
              <ProtectedRoute path="/audio" exact component={AudioInterview} allowed={["admin"]} />
              <ProtectedRoute
                path="/audio/:vacancy_id"
                exact
                component={AudioInterviewQuestions}
                allowed={["admin"]}
              />
              <ProtectedRoute
                path="/vacancy/:vacancy_id/report"
                exact
                component={Report}
                allowed={["admin"]}
              />
              <Route component={NotFound} />
            </Switch>
          </Content>
          {isLoggedIn && <Footer />}
        </Router>
      </Layout>
    </Spin>
  );
}

export default App;
