import { useEffect, useState } from "react";
import Button from "antd/lib/button";
import { Col, Row } from "antd/lib/grid";
import { Search, Voice } from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import ContentWrapper from "../components/ContentWrapper";
import { WhiteAroundBlock } from "../shared/block.styled";
import { FooterControl } from "../shared/footer.styled";
import { StyledSelect } from "../shared/forms.styled";
import { InfoMessage } from "../shared/text.styled";
import { RootState } from "../store";
import { fetchVacancies } from "../store/vacancies.reducer";

const AudioInterview: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const vacancies = useSelector((state: RootState) => state.vacancies);
  const [vacancyId, setVacancyId] = useState<string | undefined>();
  const vacancy = vacancies.find((vacancy) => vacancy.id === vacancyId);

  useEffect(() => {
    vacancies.length === 0 && dispatch(fetchVacancies());
  }, [dispatch, vacancies.length]);

  const options = vacancies.map((item) => ({
    label: item.name,
    value: item.id,
    disabled: ["running", "ended"].includes(item.interview_status) || item.resume_count === 0,
  }));

  return (
    <ContentWrapper
      title="Аудиоинтервью"
      icon={<Voice set="light" primaryColor="#98A4BC" size={20} />}
      onBack={() => null}
      footerContent={
        <FooterControl>
          <Button
            size="large"
            type="primary"
            disabled={!vacancyId}
            onClick={() => history.push(`/audio/${vacancyId}`, { vacancy })}
          >
            Далее
          </Button>
        </FooterControl>
      }
    >
      <WhiteAroundBlock>
        <Row>
          <Col span={12} offset={6}>
            <StyledSelect
              suffixIcon={<Search set="light" primaryColor="#5061DC" size={20} />}
              allowClear
              showSearch
              size="large"
              options={options}
              optionFilterProp="label"
              onChange={(value: unknown) => setVacancyId(value as string)}
              placeholder="Введите название вакансии..."
            />
          </Col>
          <Col span={12} offset={6}>
            <InfoMessage align="center" style={{ margin: "2rem" }}>
              Введите название размеченной вакансии, для которой необходимо создать шаблон
              аудиоинтервью или просмотреть отчет.
            </InfoMessage>
          </Col>
        </Row>
      </WhiteAroundBlock>
    </ContentWrapper>
  );
};

export default AudioInterview;
